<template>
    <div class="relative">
      <div v-if="loading" class="h-10rem">
        <div class="imab-logo-animated" style="left: 30%; top: 10%;">
          <div><span class="I">I</span><span class="M">M</span><span class="A">A</span><span class="B">B</span></div>
        </div>
      </div>
      <div v-else>
        <section v-if="hasMarkedRosters" class="pb-3 text-sm text-yellow-600 font-bold" aria-live="polite">
          <p class="m-0 p-0">You cannot edit a marked roster. Please go back and select another one.</p>
        </section>
        <DataTable :value="reactiveRosters" aria-label="Roster Table">
          <Column field="employee_name" header="Employee" class="text-600 w-3 pl-3">
            <template #body="slotProps">
              <div class="flex items-center gap-2 pl-1">
                <div>
                  <p class="block font-bold pb-1 m-0 p-0">
                    {{ slotProps.data.employee_name }}
                    <span v-if="slotProps.data.roster_marked" class="text-xs text-yellow-600 pl-2" aria-label="Marked">[MARKED]</span>
                  </p>
                  <p class="block text-xs text-300 m-0 p-0 pb-1">{{ slotProps.data.employee_company_name }}</p>
                  <p class="text-xs text-300 mr-2 m-0 p-0">
                    <span class="mr-2">@{{ slotProps.data.employee_id }}</span>
                    <span class="mr-2">{{ slotProps.data.employee_status }}</span>
                    <span class="mr-2">{{ slotProps.data.employee_department }}</span>
                  </p>
                </div>
              </div>
            </template>
          </Column>
          <Column field="shift_type" header="Type" class="text-600 w-1">
            <template #body="slotProps">
              <div class="items-center gap-2">
                <p class="block pb-1 m-0 p-0">
                  <Tag severity="info" :class="
                                  {'bg-yellow-800': slotProps.data.shift_type==='LEAVE', 
                                  'bg-cyan-800': slotProps.data.shift_type==='COVER', 
                                  'surface-300': slotProps.data.shift_type==='SHIFT'}" style="letter-spacing: 2px;">
                                  {{ slotProps.data.shift_type }}
                  </Tag>
                </p>
                <p class="block text-xs text-300 m-0 p-0">ID #{{ slotProps.data.roster_id }}</p>
              </div>
            </template>
          </Column>
          <Column field="roster_day" header="Day" class="text-600 w-1">
            <template #body="slotProps">
              <div class="items-center gap-2">
                <p class="block pb-1 m-0 p-0">{{ slotProps.data.roster_day }}</p>
                <p class="block text-xs text-300 m-0 p-0">{{ slotProps.data.roster_month }}</p>
              </div>
            </template>
          </Column>
          <Column field="roster_start_date" header="Date" class="text-600 w-3">
            <template #body="slotProps">
              <Calendar 
                v-model="slotProps.data.roster_start_date" 
                showIcon 
                :showOnFocus="false"
                dateFormat="yy-mm-dd" 
                :manualInput="false"
                :disabled="isSubmittingRoster || isSubmittingLeave || isDeleting || hasMarkedRosters"
                @date-select="updateMonthAndDay(slotProps.data)"
                aria-label="Start Date"
              />
            </template>
          </Column>
          <Column field="roster_start_time" header="Start" class="text-600 w-2">
            <template #body="slotProps">
              <div class="flex">
                <div>
                  <Calendar 
                    id="calendar-timeonly" 
                    v-model="slotProps.data.roster_start_time" 
                    timeOnly 
                    :disabled="isSubmittingRoster || isSubmittingLeave || isDeleting || hasMarkedRosters"
                    aria-label="Start Time"
                  />
                </div>
                <div v-if="!hasMarkedRosters && reactiveRosters && reactiveRosters.length > 1">
                  <Button icon="pi pi-arrow-down" @click="handleUpdateStartTimesBelow(slotProps.data)" :disabled="slotProps.data.day_off" class="ml-2 w-2rem surface-200 hover:surface-400" />
                </div>
              </div>
            </template>
          </Column>
          <Column field="roster_end_time" header="End" class="text-600 w-2">
            <template #body="slotProps">
              <div class="flex">
                <div>
                  <Calendar 
                    id="calendar-timeonly" 
                    v-model="slotProps.data.roster_end_time" 
                    timeOnly 
                    :disabled="isSubmittingRoster || isSubmittingLeave || isDeleting || hasMarkedRosters"
                    aria-label="End Time"
                    />
                </div>
                <div v-if="!hasMarkedRosters && reactiveRosters && reactiveRosters.length > 1">
                  <Button icon="pi pi-arrow-down" @click="handleUpdateEndTimesBelow(slotProps.data)" :disabled="slotProps.data.day_off" class="ml-2 w-2rem surface-200 hover:surface-400" />
                </div>
              </div>
            </template>
          </Column>
          <Column field="duration" header="Duration" class="text-600 w-1  text-center">
            <template #body="slotProps">
              <Tag severity="info" :class="{
                                    'bg-red-600 block text-900': calculateDuration(slotProps.data.roster_start_time, slotProps.data.roster_end_time) <= 0,
                                    'surface-200 block text-900': calculateDuration(slotProps.data.roster_start_time, slotProps.data.roster_end_time) > 0
                                    }">
                                    {{ calculateDuration(slotProps.data.roster_start_time, slotProps.data.roster_end_time) }}</Tag>
            </template>
          </Column>
        </DataTable>
        <footer class="flex overflow-hidden mt-3">
          <div class="flex-shrink-0 flex align-items-center justify-content-left mr-2">
            <Button label="Delete Roster" icon="pi pi-trash" @click="submitDelete" :loading="isDeleting" :disabled="isSubmittingRoster || isSubmittingLeave || hasMarkedRosters" type="submit" class="w-full bg-red-700 hover:bg-red-500" aria-label="Delete selected rosters" />
          </div>
          <div class="flex-shrink-1 flex-grow-1 flex align-items-center justify-content-left mr-2">
            <Button v-if="hasLeaveShifts" 
                    label="Mark as Shift" 
                    icon="pi pi-check-circle" 
                    @click="submitUpdate('SHIFT')" 
                    :loading="isSubmittingShift" 
                    :disabled="isSubmittingRoster || isSubmittingLeave || isDeleting || hasMarkedRosters" 
                    type="submit" 
                    class="bg-cyan-800 hover:bg-cyan-500" 
                    aria-label="Mark selected leave as shift" />
            <Button v-else 
                    label="Mark Leave" 
                    icon="pi pi-minus-circle" 
                    @click="submitUpdate('LEAVE')" 
                    :loading="isSubmittingLeave" 
                    :disabled="isSubmittingRoster || isSubmittingLeave || isDeleting || hasMarkedRosters" 
                    type="submit" 
                    class="bg-yellow-800 hover:bg-yellow-500" 
                    aria-label="Mark selected shifts as leave" />
          </div>
          <div class="flex-shrink-0 flex align-items-center justify-content-end mr-2">
            <Button label="Cancel" class="w-full surface-300 hover:surface-500" @click="handleBack" :disabled="isSubmittingRoster || isSubmittingLeave || isDeleting" aria-label="Cancel and go back" />
          </div>
          <div class="flex-shrink-0 flex align-items-center justify-content-end">
            <Button label="Save Roster" severity="primary" type="submit" @click="submitUpdate" :loading="isSubmittingRoster" :disabled="isDeleting || hasMarkedRosters" class="w-full hover:bg-primary-300" aria-label="Edit selected rosters" />
          </div>
        </footer>
      </div>
    </div>
</template>

<script setup>
import { onMounted, watch, defineProps, defineEmits, ref, computed } from 'vue';
import { useRosterBulk } from '@/composables/api/useRosterBulk';
import { useRosterBulkUpdate } from '@/composables/api/useRosterBulkUpdate';
import { useRosterBulkDelete } from '@/composables/api/useRosterBulkDelete';
import { useUtils } from '@/composables/useUtils';
import { useToast } from 'primevue/usetoast';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Calendar from 'primevue/calendar';
import Tag from 'primevue/tag';
import Button from 'primevue/button';

// Emits & Props
const emit = defineEmits(['updateSuccess', 'handleBack']);
const props = defineProps({
  selectedRosterIds: {
    type: Array,
    required: true
  }
});

// Api Calls
const { bulkRosters, loading, error, fetchBulkRosters } = useRosterBulk();
const { isSubmittingRoster, isSubmittingLeave, isSubmittingShift, updateRosters } = useRosterBulkUpdate();
const { isDeleting, bulkDeleteRoster } = useRosterBulkDelete();
const { 
  updateStartTimesBelow, 
  updateEndTimesBelow, 
  parseTime, 
  updateMonthAndDay, 
  getHoursAndMinutes, 
  getDateComponents,
  calculateDuration
} = useUtils();

// Refs
const reactiveRosters = ref([]);
const toast = useToast();

// Computed
const hasMarkedRosters = computed(() => {
  return reactiveRosters.value.some(roster => roster.roster_marked === true);
});

// Add a new computed property to check if there are any LEAVE shifts
const hasLeaveShifts = computed(() => {
  return reactiveRosters.value.some(roster => roster.shift_type === 'LEAVE');
});

// Functions
const handleUpdateStartTimesBelow = (currentRow) => {
  updateStartTimesBelow(reactiveRosters.value, currentRow, (updatedRosters) => {
    reactiveRosters.value = updatedRosters;
  });
};

const handleUpdateEndTimesBelow = (currentRow) => {
  updateEndTimesBelow(reactiveRosters.value, currentRow, (updatedRosters) => {
    reactiveRosters.value = updatedRosters;
  });
};

const handleBack = async () => {
    emit('handleBack');
}

const loadRosterData = async () => {
  try {
    await fetchBulkRosters(props.selectedRosterIds);
    reactiveRosters.value = bulkRosters.value.map(roster => ({
      ...roster,
      roster_start_date: new Date(roster.roster_start_date),
      roster_start_time: parseTime(roster.roster_start_time),
      roster_end_time: parseTime(roster.roster_end_time)
    }));
  } catch (error) {
    console.error('Error fetching roster data:', error);
    toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to load roster data', life: 12000 });
  }
};

// Bulk Edit Roster
const submitUpdate = async (action = '') => {
    const formattedRosters = reactiveRosters.value
      .map(roster => {
          const [startHours, startMinutes] = getHoursAndMinutes(roster.roster_start_time);
          const [endHours, endMinutes] = getHoursAndMinutes(roster.roster_end_time);
          const dateComponents = getDateComponents(roster.roster_start_date);

          if (!dateComponents) return null;

          const [year, month, day] = dateComponents;
          
          let rosterStart = Date.UTC(year, month, day, startHours, startMinutes);
          let rosterEnd = Date.UTC(year, month, day, endHours, endMinutes);
          
          if (rosterEnd < rosterStart) {
              rosterEnd = Date.UTC(year, month, day + 1, endHours, endMinutes);
          }

          return {
              rosterId: roster.roster_id,
              employeeId: roster.employee_id,
              rosterMonth: roster.roster_month,
              rosterShift: action === 'LEAVE' ? 'LEAVE' : (action === 'SHIFT' ? 'SHIFT' : roster.shift_type),
              rosterDay: roster.roster_day,
              rosterStart,
              rosterEnd,
          };
      })
      .filter(Boolean);

    if (formattedRosters.length === 0) {
      toast.add({ severity: 'error', summary: 'Error', detail: 'No valid roster data to update', life: 12000 });
      if (action === 'LEAVE') isSubmittingLeave.value = false;
      else if (action === 'SHIFT') isSubmittingShift.value = false;
      else isSubmittingRoster.value = false;
      return;
    }

    const result = await updateRosters(formattedRosters, action);

    if (result.success) {
        toast.add({
          severity: 'success',
          summary: 'Success',
          detail: result.message,
          life: 4000,
          group: 'br'
        });
        emit('updateSuccess');
    } else {
        toast.add({
          severity: result.severity || 'error',
          summary: 'Error',
          detail: result.message,
          life: 12000,
          group: 'br'
        });
    }
};

// Bulk Delete Roster
const submitDelete = async () => {
  const rosterIds = reactiveRosters.value.map(roster => roster.roster_id);
  const employeeIds = reactiveRosters.value.map(roster => roster.employee_id);

  const result = await bulkDeleteRoster(rosterIds, employeeIds);

  if (result.success) {
  toast.add({
    severity: 'success',
    summary: 'Success',
    detail: result.message,
    life: 6000,
    group: 'br'
  });
  emit('updateSuccess');
  } else {
      toast.add({
        severity: result.severity || 'error',
        summary: 'Error',
        detail: result.message,
        life: 12000,
        group: 'br'
      });
  }
};


// Watch for errors from useRosterBulk
watch(error, (newError) => {
  if (newError) {
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: `Failed to fetch roster data: ${newError}`,
      life: 12000,
      group: 'br'
    });
  }
});

onMounted(loadRosterData);
watch(() => props.selectedRosterIds, loadRosterData);
</script>