import { ref } from 'vue';
import axiosClient from '@/axiosClient';
import { API_ENDPOINTS } from '@/apiConfig';

export function useEmployeeSingle() {
  const employee = ref(null);
  const loading = ref(false);
  const error = ref(null);

  async function fetchEmployeeById(employeeId) {
    loading.value = true;
    error.value = null;

    try {
      const url = API_ENDPOINTS.fetchAllEmployees
        .replace('{employeeId}', employeeId)
        .replace('{companyId}', 0)
        .replace('{archiveStatus}', 0);

      const response = await axiosClient.get(url);
      
      if (response.data && response.data.length > 0) {
        const employeeData = response.data[0];
        employee.value = {
          employee_name: employeeData.employee_name,
          employee_id: employeeData.employee_id,
          employee_company_id: employeeData.company.company_id,
          employee_company_name: employeeData.company.company_name,
          employee_badge_id: employeeData.employee_badge_id,
          employee_badge_pin: employeeData.employee_badge_pin,
          employee_contract: employeeData.employee_contract,
          employee_department: employeeData.employee_department,
          employee_email: employeeData.employee_email,
          employee_join_date: employeeData.employee_join_date,
          employee_contract_date: employeeData.employee_contract_date,
          employee_status: employeeData.employee_status,
          employee_photo_url: employeeData.employee_photo_url,
          employee_extra_bonuses: employeeData.employee_extra_bonuses
        };
      } else {
        error.value = 'Employee not found';
      }
    } catch (err) {
      handleError(err);
    } finally {
      loading.value = false;
    }
  }

  async function updateEmployee(employeeData) {
    loading.value = true;
    error.value = null;

    try {
      const response = await axiosClient.put(API_ENDPOINTS.updateEmployee, employeeData);
      employee.value = response.data;
      return response.data;
    } catch (err) {
      handleError(err);
      throw err;  // Re-throw the error for the component to handle if needed
    } finally {
      loading.value = false;
    }
  }

  function handleError(err) {
    console.error('Error:', err);
    if (axiosClient.isAxiosError(err)) {
      if (err.response) {
        console.error('Error response:', err.response.data);
        console.error('Error status:', err.response.status);
        if (err.response.status === 409) {
          error.value = `${err.response.data.error} Field: ${err.response.data.field}`;
        } else {
          error.value = err.response.data.error || `Server error: ${err.response.status}`;
        }
      } else if (err.request) {
        console.error('Error request:', err.request);
        error.value = 'No response from server';
      } else {
        console.error('Error message:', err.message);
        error.value = err.message;
      }
    } else {
      error.value = 'An unexpected error occurred';
    }
  }

  return {
    employee,
    loading,
    error,
    fetchEmployeeById,
    updateEmployee
  };
}
