<template>
    <div class="relative">
        <form @submit.prevent="handleSubmit" v-if="employee">
            <div class="grid">
                <div class="col-12 md:col-6">
                    <h3>Employee Info</h3>
                    <div class="grid">
                        <div class="col-12 mb-1">
                            <label for="employee_id" class="block text-xs text-gray-400 mb-1">Employee ID</label>
                            <InputText id="employee_id" disabled="true" v-model="employee.employee_id" class="w-full" />
                        </div>
                        <div class="col-12 mb-1">
                            <label for="employee_name" class="block text-xs text-gray-400 mb-1">Name</label>
                            <InputText id="employee_name" v-model="employee.employee_name" :disabled="isUpdating" class="w-full" />
                        </div>
                        <div class="col-12 mb-1">
                            <label for="employee_email" class="block text-xs text-gray-400 mb-1">Email</label>
                            <InputText id="employee_email" v-model="employee.employee_email" :disabled="isUpdating" class="w-full" />
                        </div>
                        <div class="col-12 mb-1">
                            <label for="employee_badge_id" class="block text-xs text-gray-400 mb-1">Badge ID</label>
                            <InputText id="employee_badge_id" v-model="employee.employee_badge_id" :disabled="isUpdating" class="w-full" />
                        </div>
                        <div class="col-12 mb-1">
                            <label for="employee_badge_pin" class="block text-xs text-gray-400 mb-1">Badge PIN</label>
                            <InputText id="employee_badge_pin" v-model="employee.employee_badge_pin" :disabled="isUpdating" class="w-full" />
                        </div>
                    </div>
                </div>

                <div class="col-12 md:col-6">
                    <h3>Contract Info</h3>
                    <div class="grid">
                        <div class="col-12 mb-2">
                            <label for="employee_status" class="block text-xs text-gray-400 mb-1">Status</label>
                            <Dropdown id="employee_status" v-model="employee.employee_status" :options="statusOptions" :disabled="isUpdating" optionLabel="name" optionValue="code" class="w-full" />
                        </div>
                        <div class="col-12 mb-1">
                            <label for="employee_department" class="block text-xs text-gray-400 mb-1">Department</label>
                            <Dropdown id="employee_department" v-model="employee.employee_department" :disabled="isUpdating" :options="departmentOptions" optionLabel="name" optionValue="code" class="w-full" />
                        </div>
                        <div class="col-12 mb-1">
                            <label for="employee_company_id" class="block text-xs text-gray-400 mb-1">Company</label>
                            <Dropdown id="employee_company_id" v-model="employee.employee_company_id" :disabled="isUpdating" :options="companiesList" optionLabel="company_name" optionValue="company_id" :loading="employeesListLoading" class="w-full" />
                        </div>
                        <div class="col-12 mb-2">
                            <label for="employee_join_date" class="block text-xs text-gray-400 mb-1">Join Date</label>
                            <Calendar id="employee_join_date" dateFormat="yy-mm-dd" v-model="employee.employee_join_date" disabled="true" class="w-full" />
                        </div>
                        <div class="col-12 mb-2">
                            <label for="employee_contract_date" class="block text-xs text-gray-400 mb-1">Contract Date</label>
                            <Calendar id="employee_contract_date" dateFormat="yy-mm-dd" v-model="employee.employee_contract_date" disabled="true" class="w-full" />
                        </div>
                        <div class="col-12 mb-2">
                            <label for="employee_contract" class="block text-xs text-gray-400 mb-1">Contract</label>
                            <InputText id="employee_contract" v-model="employee.employee_contract" disabled="true"  class="w-full" />
                        </div>
                        <div class="col-12 mb-2">
                            <label for="employee_extra_bonuses" class="block text-xs text-gray-400 mb-1">Extra Bonuses</label>
                            <InputText id="employee_extra_bonuses" v-model="employee.employee_extra_bonuses" disabled="true" class="w-full" />
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div v-else-if="loading" class="h-10rem">
            <div class="imab-logo-animated" style="left: 30%; top: 10%;">
            <div><span class="I">I</span><span class="M">M</span><span class="A">A</span><span class="B">B</span></div>
            </div>
        </div> 
        <p v-else-if="error">{{ error }}</p>
        <footer class="flex overflow-hidden mt-3">
            <div class="flex-shrink-0 flex align-items-start justify-content-start mr-2 mt-1">
                <Button label="Cancel" :disabled="isUpdating" class="surface-200 hover:surface-400" @click="handleBack" aria-label="Cancel and go back" />
            </div>
            <div class="flex-grow-1 flex align-items-start justify-content-end">
                <Button label="Edit Employee" :disabled="loading" :loading="isUpdating" class="w-full bg-primary hover:surface-900 px-3 mt-1" @click="handleSubmit" aria-label="Submit" />
            </div>
        </footer>
    </div>
</template>

<script setup>
import { onMounted, defineProps, defineEmits } from 'vue';
import { useEmployeeSingle } from '@/composables/api/useEmployeeSingle';
import { useEmployeeSingleUpdate } from '@/composables/api/useEmployeeSingleUpdate';
import { useEmployees } from '@/composables/api/useEmployees';
import { useToast } from 'primevue/usetoast';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';

// Add this new import
import { ref, watch } from 'vue';

const props = defineProps({
    selectedId: {
        type: Number,
        required: true
    }
});

const emit = defineEmits(['handleBack', 'employeeUpdated']);

const { companiesList, employeesListLoading } = useEmployees();
const { employee, loading, error, fetchEmployeeById } = useEmployeeSingle();
const { isUpdating, updateEmployee } = useEmployeeSingleUpdate();
const toast = useToast();

const statusOptions = [   
  { name: 'Permanent', code: 'Permanent' },
  { name: 'Probation', code: 'Probation' },
  { name: 'Trainee', code: 'Trainee' },
  { name: 'Resigned', code: 'Resigned' },
  { name: 'Terminated', code: 'Terminated' }
];

const departmentOptions = [   
  { name: 'CSR Staff', code: 'HR / Operator' },
  { name: 'Covering Staff', code: 'HR / Shift Cover' },
  { name: 'House keeping', code: 'House Keeping' },
  { name: 'Tech Staff', code: 'Tech' },
  { name: 'Manager', code: 'Manager' },
  { name: 'Maintenance', code: 'Maintenance' }
];

onMounted(() => {
    fetchEmployeeById(props.selectedId);
});

const handleBack = () => {
    emit('handleBack');
};

const handleSubmit = async () => {
    // Check if any required fields are blank
    const requiredFields = [
        'employee_name',
        'employee_email',
        'employee_badge_id',
        'employee_badge_pin',
        'employee_department',
        'employee_company_id',
        'employee_status',
        'employee_join_date'
    ];

    const missingFields = requiredFields.filter(field => !employee.value[field]);

    if (missingFields.length > 0) {
        toast.add({
            severity: 'error',
            summary: 'Validation Error',
            detail: `Please fill in all required fields: ${missingFields.join(', ')}`,
            life: 5000,
            group: 'br'
        });
        return;
    }

    // Create a payload with only the changed fields, always including employee_id
    const changedFields = {
        employee_id: employee.value.employee_id // Always include employee_id
    };

    for (const [key, value] of Object.entries(employee.value)) {
        if (key !== 'employee_id' && JSON.stringify(value) !== JSON.stringify(initialEmployeeData.value[key])) {
            changedFields[key] = value;
        }
    }

    // If no fields have changed (other than employee_id), show a message and return
    if (Object.keys(changedFields).length === 1) { // Only employee_id is present
        toast.add({
            severity: 'info',
            summary: 'No Changes',
            detail: 'No changes were made to the employee data.',
            life: 3000,
            group: 'br'
        });
        return;
    }

    try {
        const updatedEmployee = await updateEmployee(changedFields);
        toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Employee updated successfully',
            life: 3000,
            group: 'br'
        });
        // Update the initialEmployeeData with the new values
        initialEmployeeData.value = JSON.parse(JSON.stringify(employee.value));
        // Notify the parent component
        emit('employeeUpdated', updatedEmployee);
    } catch (err) {
        // The axiosClient will handle token refresh and retries
        // If we reach this catch block, it means all retries have failed
        let errorMessage = 'An error occurred while updating the employee';
        if (err.response && err.response.data) {
            if (err.response.status === 409) {
                errorMessage = `${err.response.data.error} Field: ${err.response.data.field}`;
            } else {
                errorMessage = err.response.data.error || errorMessage;
            }
        }
        toast.add({
            severity: 'error',
            summary: 'Update Failed',
            detail: errorMessage,
            life: 5000,
            group: 'br'
        });
    }
};

// Create a ref to store the initial employee data
const initialEmployeeData = ref(null);

// Watch for changes in the employee data and store the initial state
watch(employee, (newValue) => {
  if (newValue && !initialEmployeeData.value) {
    initialEmployeeData.value = JSON.parse(JSON.stringify(newValue));
  }
}, { immediate: true });
</script>