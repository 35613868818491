<template>
    <div class="relative">
      <div v-if="loading" class="h-10rem">
        <div class="imab-logo-animated" style="left: 40%; top: 10%;">
          <div><span class="I">I</span><span class="M">M</span><span class="A">A</span><span class="B">B</span></div>
        </div>
      </div>
      <div v-else>
        <DataTable :value="reactiveRosters" :rowClass="rowClass"  :rowStyle="rowStyle" aria-label="Roster Table"
        scrollable scrollHeight="600px" tableStyle="min-width: 50rem">
          <!-- Add a new Column for the row counter -->
          <Column header="#" class="w-1 text-center text-2xl text-300" style="width: 0.1rem !important">
            <template #body="slotProps">
              <span v-if="!slotProps.data.database_entry">{{ getManualRowNumber(slotProps.data) }}</span>
            </template>
          </Column>
          
          <Column field="employee_name" header="Employee" class="text-600 w-3 pl-3">
            <template #body="slotProps">
              <div class="flex items-center gap-1 pl-1">
                <!-- Add the row counter to the left of the pencil icon -->
                <Button icon="pi pi-pencil" 
                        @click="openChangeCompanyDialog(slotProps.data)" 
                        :disabled="slotProps.data.day_off || slotProps.data.roster_marked || slotProps.data.database_entry" 
                        class="flex text-xs w-2rem surface-200 hover:surface-400 mr-1" />
                <div>
                  <p class="block font-bold pb-1 m-0 p-0">
                    {{ slotProps.data.employee_name }}
                    <span v-if="slotProps.data.roster_marked" class="text-xs text-yellow-600 pl-2" aria-label="Marked">[MARKED]</span>
                  </p>
                  <p :class="{
                    'font-bold text-cyan-300': slotProps.data.employee_company_id !== 1,
                    'text-xs m-0 p-0 pb-1': true,
                    }">{{ slotProps.data.employee_company_name }}</p>
                </div>
              </div>
            </template>
          </Column>
          <Column field="day_off" header="Schedule" class="text-600 w-1">
            <template #body="slotProps">
              <Tag severity="info" class="bg-yellow-900 block mr-1 uppercase text-center" v-if="slotProps.data.day_off && !slotProps.data.roster_marked">Off</Tag>
              <Tag severity="info" :class="{'bg-cyan-800': slotProps.data.shift_type === 'COVER', 'surface-300 block mr-1 uppercase text-center': true}" 
                                    v-if="!slotProps.data.day_off">{{ slotProps.data.shift_type }}</Tag>
            </template>
          </Column>
          <Column field="roster_day" header="Day" class="text-600 w-1">
            <template #body="slotProps">
              <div class="items-center">
                <p class="block pb-1 m-0 p-0">{{ slotProps.data.roster_day }}</p>
                <p class="block text-xs text-300 m-0 p-0">{{ slotProps.data.roster_month }}</p>
              </div>
            </template>
          </Column>
          <Column field="roster_start_date" header="Date" class="text-600 w-3">
            <template #body="slotProps">
              <Calendar 
                v-model="slotProps.data.roster_start_date" 
                showIcon 
                :showOnFocus="false"
                dateFormat="yy-mm-dd" 
                :disabled="isSubmitting || isDeleting || slotProps.data.database_entry || slotProps.data.day_off"
                @date-select="updateMonthAndDay(slotProps.data)"
                aria-label="Start Date"
              />
            </template>
          </Column>
          <Column field="roster_start_time" header="Start" class="text-600 w-2">
            <template #body="slotProps">
              <div class="flex">
                <div>
                  <Calendar 
                    id="calendar-timeonly" 
                    v-model="slotProps.data.roster_start_time" 
                    timeOnly 
                    :disabled="isSubmitting || isDeleting || slotProps.data.database_entry || slotProps.data.day_off"
                    aria-label="Start Time"
                  />
                </div>
                <div v-if="!slotProps.data.database_entry && hasMultipleDatabaseEntries && slotProps.data.shift_type !== 'COVER'">
                  <Button icon="pi pi-arrow-down" @click="handleUpdateStartTimesBelow(slotProps.data)" :disabled="slotProps.data.day_off" class="ml-2 w-2rem surface-200 hover:surface-400" />
                </div>
              </div>
            </template>
          </Column>
          <Column field="roster_end_time" header="End" class="text-600 w-2">
            <template #body="slotProps">
              <div class="flex">
                <div>
                  <Calendar 
                    id="calendar-timeonly" 
                    v-model="slotProps.data.roster_end_time" 
                    timeOnly 
                    :disabled="isSubmitting || isDeleting || slotProps.data.database_entry || slotProps.data.day_off"
                    aria-label="End Time"
                    />
                </div>
                <div v-if="!slotProps.data.database_entry && hasMultipleDatabaseEntries && slotProps.data.shift_type !== 'COVER'">
                  <Button icon="pi pi-arrow-down" @click="handleUpdateEndTimesBelow(slotProps.data)" :disabled="slotProps.data.day_off" class="ml-2 w-2rem surface-200 hover:surface-400" />
                </div>
              </div>
            </template>
          </Column>
          <Column field="duration" header="Duration" class="text-600 w-1  text-center">
            <template #body="slotProps">
              <Tag severity="info" :class="{
                                    'bg-red-600 block text-900': calculateDuration(slotProps.data.roster_start_time, slotProps.data.roster_end_time) <= 0,
                                    'surface-200 block text-900': calculateDuration(slotProps.data.roster_start_time, slotProps.data.roster_end_time) > 0
                                    }">
                                    {{ calculateDuration(slotProps.data.roster_start_time, slotProps.data.roster_end_time) }}</Tag>
            </template>
          </Column>
          <Column field="status" header="Status" class="text-600 w-2">
            <template #body="slotProps">
              <div class="flex pr-2">
                <Button 
                  :disabled="isSubmitting || isDeleting || slotProps.data.database_entry || !hasMultipleDatabaseEntries || slotProps.data.shift_type === 'COVER'" @click="toggleDayOff(slotProps.data)" 
                  :icon="getDayOffIcon(slotProps.data)" :class="['ml-2', slotProps.data.day_off ? 'surface-100 hover:surface-200' : 'surface-200 hover:surface-400']" />
                <Button :disabled="isSubmitting || isDeleting || slotProps.data.database_entry || !hasMultipleDatabaseEntries" 
                    @click="removeRow(slotProps.data)" 
                    icon="pi pi-trash" 
                    class="ml-2 bg-red-900 hover:bg-red-600" />
              </div>
            </template>
          </Column>
        </DataTable>
        <footer class="flex overflow-hidden mt-3">
          <div class="flex-shrink-0 flex align-items-center justify-content-start mr-2">
            <Button label="Cancel" class="w-full surface-200 hover:surface-400" @click="handleBack" :disabled="isSubmitting || isDeleting" aria-label="Cancel and go back" />
          </div>
          <div class="flex-grow-1 flex-shrink-1 flex align-items-center justify-content-start">
            <Button label="Generate Roster" severity="primary" type="submit" @click="submitForm" :loading="isSubmitting" :disabled="isDeleting || hasNoneDatabaseEntries" class="bg-primary-600 hover:bg-primary-400" aria-label="Generate roster" />
          </div>
          <div class="flex-shrink-0 flex align-items-center justify-content-end w-5 gap-1"><span class="text-300 text-xs pr-1">Add New Rows:</span>
            <Button label="+1" type="submit" @click="createNewRows(1)" :disabled="isDeleting || isSubmitting || isMaxRowsReached" class="surface-300 hover:surface-500" aria-label="New 1" />
            <Button label="+2" type="submit" @click="createNewRows(2)" :disabled="isDeleting || isSubmitting || isMaxRowsReached" class="surface-300 hover:surface-500" aria-label="New 3" />
            <Button label="+6" type="submit" @click="createNewRows(6)" :disabled="isDeleting || isSubmitting || isMaxRowsReached" class="surface-300 hover:surface-500" aria-label="New 3" />
            <Button label="+13" type="submit" @click="createNewRows(13)" :disabled="isDeleting || isSubmitting || isMaxRowsReached" class="surface-300 hover:surface-500" aria-label="New 7" />
            <Button label="+30" type="submit" @click="createNewRows(30)" :disabled="isDeleting || isSubmitting || isMaxRowsReached" class="surface-300 hover:surface-500" aria-label="New 14" />
            <Button label="+60" type="submit" @click="createNewRows(60)" :disabled="isDeleting || isSubmitting || isMaxRowsReached" class="surface-300 hover:surface-500" aria-label="New 30" />
          </div>
          <div class="flex-shrink-0 flex align-items-center justify-content-end w-1 mr-5 gap-1">
          </div>
        </footer>
      </div>
    </div>
    <Dialog v-model:visible="changeCompany" :style="{ width: '20vw' }" :modal="true">
      <template #header>
          <h2 class="w-full m-0 p-0">Select Cover Company</h2>
      </template>
      <Dropdown v-model="selectedCompany" :options="companiesList" filter optionLabel="company_name" placeholder="All Companies" class="w-full">
        <template #value="slotProps">
            <div v-if="slotProps.value" class="flex align-items-center">
                {{ slotProps.value.company_name }}
            </div>
            <span v-else class="text-sm text-300">
                {{ slotProps.placeholder }}
            </span>
        </template>
        <template #option="slotProps">
            <div class="flex align-items-center">
                {{ slotProps.option.company_name }}
            </div>
        </template>
      </Dropdown>
        <footer class="flex overflow-hidden mt-3">
          <div class="flex-shrink-0 flex align-items-center justify-content-start mr-2">
            <Button label="Cancel" class="w-full surface-200 hover:surface-400" @click="cancelChangeCompany" />
          </div>
          <div class="flex-grow-1 flex-shrink-1 flex align-items-center justify-content-start">
            <Button label="Update Company" severity="primary" type="submit" @click="updateCompany" />
          </div>
        </footer>
    </Dialog>
</template>

<script setup>
import { onMounted, watch, defineProps, defineEmits, ref, computed } from 'vue';
import { useRosterSingle } from '@/composables/api/useRosterSingle';
import { useRosterBulkCreate } from '@/composables/api/useRosterBulkCreate';
import { useUtils } from '@/composables/useUtils';
import { useToast } from 'primevue/usetoast';
import { useUserSelections } from '@/composables/useUserSelections';
import DataTable from 'primevue/datatable';
import Dropdown from 'primevue/dropdown';
import Column from 'primevue/column';
import Calendar from 'primevue/calendar';
import Button from 'primevue/button';
import Tag from 'primevue/tag';
import Dialog from 'primevue/dialog';
import { v4 as uuidv4 } from 'uuid';

// Emits and Props
const emit = defineEmits(['updateSuccess', 'handleBack']);
const props = defineProps({
  selectedId: {
    type: Object,
    required: true
  },
  numberOfRows: {
    type: Number,
    default: 6
  },
  shiftType: {
    type: String,
    default: 'SHIFT'
  },
});

// Api calls
const { singleRoster, loading, error, warning, fetchSingleRoster } = useRosterSingle();
const { isSubmitting, submitBulkCreate } = useRosterBulkCreate();
const { 
  updateStartTimesBelow, 
  updateEndTimesBelow, 
  parseTime, 
  updateMonthAndDay, 
  getHoursAndMinutes, 
  getDateComponents,
  calculateDuration,
  generateBatchName,
  getDayName, 
  getMonthName
} = useUtils();

const { companiesList } = useUserSelections();

// Refs
const reactiveRosters = ref([]);
const toast = useToast();
const isDeleting = ref(false);
const selectedCompany = ref('');
const changeCompany = ref(false);


// Add these to your existing refs
const editingRowId = ref(null);

// Add these new functions
const openChangeCompanyDialog = (rowData) => {
  editingRowId.value = rowData.roster_id;
  selectedCompany.value = companiesList.value.find(company => company.company_id === rowData.employee_company_id) || null;
  changeCompany.value = true;
};

const updateCompany = () => {
  if (editingRowId.value && selectedCompany.value) {
    const index = reactiveRosters.value.findIndex(roster => roster.roster_id === editingRowId.value);
    if (index !== -1) {
      reactiveRosters.value[index] = {
        ...reactiveRosters.value[index],
        employee_company_id: selectedCompany.value.company_id,
        employee_company_name: selectedCompany.value.company_name
      };
      toast.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Company updated successfully',
        life: 3000,
        group: 'br'
      });
    }
  }
  changeCompany.value = false;
  editingRowId.value = null;
  selectedCompany.value = null;
};

const cancelChangeCompany = () => {
  changeCompany.value = false;
  editingRowId.value = null;
  selectedCompany.value = null;
};



// Functions
const rowClass = (data) => {
    return [{ 'surface-50': data.day_off === true }];
};
const rowStyle = (data) => {
    if (data.day_off === true) {
        return { opacity: 0.8 };
    }
    if (data.database_entry === true) {
        return { fontWeight: 'bold', fontStyle: 'italic', opacity: 0.5 };
    }
    
};
const handleUpdateStartTimesBelow = (currentRow) => {
  updateStartTimesBelow(reactiveRosters.value, currentRow, (updatedRosters) => {
    reactiveRosters.value = updatedRosters;
  });
};
const handleUpdateEndTimesBelow = (currentRow) => {
  updateEndTimesBelow(reactiveRosters.value, currentRow, (updatedRosters) => {
    reactiveRosters.value = updatedRosters;
  });
};
const handleBack = async () => {
    emit('handleBack');
}
const toggleDayOff = (rowData) => {
  const index = reactiveRosters.value.findIndex(roster => roster.roster_id === rowData.roster_id);
  if (index !== -1) {
    reactiveRosters.value[index] = {
      ...reactiveRosters.value[index],
      day_off: !reactiveRosters.value[index].day_off
    };
  }
};
const removeRow = (rowData) => {
  reactiveRosters.value = reactiveRosters.value.filter(roster => roster.uniqueId !== rowData.uniqueId);
};

// Load api data
const loadRosterData = async () => {
  try {
    await fetchSingleRoster(props.selectedId.employee_id, props.shiftType);
    
    let sortedRosters = [];
    
    if (singleRoster.value && singleRoster.value.length > 0) {
      sortedRosters = singleRoster.value.sort((a, b) => b.roster_start_unix - a.roster_start_unix);
      sortedRosters = sortedRosters.map(roster => ({
        ...roster,
        uniqueId: uuidv4(), // Add a unique id to each row
        roster_start_date: new Date(roster.roster_start_date),
        roster_start_time: parseTime(roster.roster_start_time),
        roster_end_time: parseTime(roster.roster_end_time),
        database_entry: true,
        day_off: false
      }));
    }

    // Find the last database entry or use today's date if no entries exist
    const lastDatabaseEntry = sortedRosters.find(roster => typeof roster.roster_id === 'number');
    const lastRoster = lastDatabaseEntry || {
      employee_name: props.selectedId.employee_name,
      employee_id: props.selectedId.employee_id,
      employee_company_id: props.selectedId.employee_company_id,
      employee_company_name: props.selectedId.employee_company_name,
      roster_start_date: new Date(),
      roster_start_time: new Date(1970, 0, 1, 9, 0), // Full timestamp for 09:00
      roster_end_time: new Date(1970, 0, 1, 17, 0), // Full timestamp for 17:00
      shift_type: props.shiftType,
      roster_id: 'new-0'
    };

    // Determine the start date for new entries
    let startDate = new Date(lastRoster.roster_start_date);

    // This ensures we start from the beginning of today
    if (!lastDatabaseEntry) {
      startDate.setHours(0, 0, 0, 0);
    }

    // Add rows (either more rows or initial rows if no data)
    for (let i = 0; i < props.numberOfRows; i++) {
      const newDate = new Date(startDate);
      newDate.setDate(newDate.getDate() + (lastDatabaseEntry ? i + 1 : i));  // Start from next day if there's data, otherwise from today

      sortedRosters.push({
        ...lastRoster,
        uniqueId: uuidv4(), // Add a unique id to each new row
        roster_id: `new-${sortedRosters.length}`,  // Temporary ID for new entries
        roster_start_date: newDate,
        roster_day: getDayName(newDate),
        roster_month: getMonthName(newDate),
        roster_start_unix: newDate.getTime(),
        roster_marked: false,
        database_entry: false,
        day_off: false
      });
    }

    // Sort to ensure all entries are in the correct order
    reactiveRosters.value = sortedRosters.sort((a, b) => a.roster_start_unix - b.roster_start_unix);

  } catch (error) {
    console.error('Error fetching roster data:', error);
    toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to load roster data', life: 12000, group: 'br' });
  }
};

// Update these computed properties and watchers
const maxAllowedRows = 80;

const totalRows = computed(() => reactiveRosters.value.length);

const isMaxRowsReached = computed(() => totalRows.value >= maxAllowedRows);

// Update the watcher for totalRows
watch(totalRows, (newValue) => {
  if (newValue === maxAllowedRows) {
    toast.add({
      severity: 'warn',
      summary: 'Warning',
      detail: `You have ${newValue} rows. Maximum allowed rows are ${maxAllowedRows}.`,
      life: 5000,
      group: 'br'
    });
  }
});

// Update the createNewRows function
const createNewRows = (numberOfRows = 1) => {
  if (isMaxRowsReached.value) {
    toast.add({ severity: 'error', summary: 'Error', detail: `Maximum allowed rows (${maxAllowedRows}) reached`, life: 3000, group: 'br' });
    return;
  }

  if (reactiveRosters.value.length === 0) {
    toast.add({ severity: 'error', summary: 'Error', detail: 'No existing rows to clone', life: 3000, group: 'br' });
    return;
  }

  const rowsToAdd = Math.min(numberOfRows, maxAllowedRows - totalRows.value);

  const lastRow = reactiveRosters.value[reactiveRosters.value.length - 1];
  let newDate = new Date(lastRow.roster_start_date);

  for (let i = 0; i < rowsToAdd; i++) {
    newDate.setDate(newDate.getDate() + 1); // Increment date by 1 day

    const newRow = {
      ...lastRow,
      uniqueId: uuidv4(), // Add a unique id to each new row
      roster_id: `new-${reactiveRosters.value.length + i}`,
      roster_start_date: new Date(newDate),
      roster_day: getDayName(newDate),
      roster_month: getMonthName(newDate),
      roster_start_unix: newDate.getTime(),
      roster_marked: false,
      database_entry: false,
      day_off: false
    };

    reactiveRosters.value.push(newRow);
  }
};

const submitForm = async () => {
    if (isSubmitting.value) return; 

    const batchName = generateBatchName();

    const formattedRosters = reactiveRosters.value
        .filter(roster => !roster.day_off && !roster.database_entry)
        .map(roster => {
            const [startHours, startMinutes] = getHoursAndMinutes(roster.roster_start_time);
            const [endHours, endMinutes] = getHoursAndMinutes(roster.roster_end_time);
            const dateComponents = getDateComponents(roster.roster_start_date);

            if (!dateComponents) return null;

            const [year, month, day] = dateComponents;
            
            let rosterStart = Date.UTC(year, month, day, startHours, startMinutes);
            let rosterEnd = Date.UTC(year, month, day, endHours, endMinutes);
            
            if (rosterEnd < rosterStart) {
                rosterEnd = Date.UTC(year, month, day + 1, endHours, endMinutes);
            }

            return {
                employee_id: roster.employee_id,
                employee_company_id: roster.employee_company_id,
                shift_type: roster.shift_type,
                roster_month: roster.roster_month,
                roster_day: roster.roster_day,
                roster_start: rosterStart,
                roster_end: rosterEnd,
                roster_requested_by: "ORION",
                batch_name: batchName,
                batch_status: 'OPEN'
            };
        })
        .filter(Boolean);

    if (formattedRosters.length === 0) {
        toast.add({ severity: 'error', summary: 'Error', detail: 'No valid roster data to create', life: 12000, group: 'br' });
        isSubmitting.value = false;
        return;
    }

    const result = await submitBulkCreate(formattedRosters);

    if (result.success) {
        toast.add({
            severity: 'success',
            summary: 'Success',
            detail: result.message,
            life: 4000,
            group: 'br'
        });
        emit('updateSuccess');
    } else {
        toast.add({
            severity: result.severity || 'error',
            summary: 'Error',
            detail: result.message,
            life: 12000,
            group: 'br'
        });
    }
};

// Computed
const hasMultipleDatabaseEntries = computed(() => {
  const nonDatabaseEntries = reactiveRosters.value.filter(roster => !roster.database_entry && !roster.day_off)
  return nonDatabaseEntries.length > 1
})
const hasNoneDatabaseEntries = computed(() => {
  const nonDatabaseEntries = reactiveRosters.value.filter(roster => !roster.database_entry && !roster.day_off)
  return nonDatabaseEntries.length === 0
})
const getDayOffIcon = computed(() => (rowData) => {
  return rowData.day_off ? 'pi pi-minus' : 'pi pi-check';
});

// Watch
watch([error, warning], ([newError, newWarning]) => {
  if (newError) {
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: `${newError}`,
      life: 12000, group: 'br'
    });
  } else if (newWarning) {
    toast.add({
      severity: 'warn',
      summary: 'Warning',
      detail: `${newWarning}`,
      life: 12000, group: 'br'
    });
  }
});

// Add this new function to get the manual row number
const getManualRowNumber = (rowData) => {
  return reactiveRosters.value.filter(row => !row.database_entry).findIndex(row => row.uniqueId === rowData.uniqueId) + 1;
};

onMounted(loadRosterData);
watch(() => props.selectedId, loadRosterData);
</script>