import { ref } from 'vue';
import axiosClient from '@/axiosClient';
import { API_ENDPOINTS } from '@/apiConfig';

export function useEmployeeSingleUpdate() {
  const isUpdating = ref(false);
  const updateError = ref(null);

  const updateEmployee = async (employeeData) => {
    isUpdating.value = true;
    updateError.value = null;

    try {
      const response = await axiosClient.put(API_ENDPOINTS.updateEmployee, employeeData);
      return response.data;
    } catch (error) {
      console.error('Error updating employee:', error);
      if (error.response) {
        updateError.value = error.response.data.message || 'An error occurred while updating the employee.';
      } else if (error.request) {
        updateError.value = 'No response received from the server.';
      } else {
        updateError.value = 'An error occurred while sending the request.';
      }
      throw error;
    } finally {
      isUpdating.value = false;
    }
  };

  return {
    isUpdating,
    updateError,
    updateEmployee
  };
}
