import { ref } from 'vue';
import { createSSRApp, h } from 'vue';
import { renderToString } from '@vue/server-renderer';
import ComplaintEmailTemplate from '@/components/emails/ComplaintEmailTemplate.vue';

export function useEmailSender() {
  const isSending = ref(false);
  const error = ref(null);

  const sendEmail = async (to, subject, complaint) => {
    isSending.value = true;
    error.value = null;

    try {
      // Create a Vue app with the ComplaintEmailTemplate
      const app = createSSRApp({
        setup() {
          return () => h(ComplaintEmailTemplate, { complaint });
        }
      });

      // Render the app to an HTML string
      const htmlContent = await renderToString(app);

      const response = await fetch('https://us-central1-imab-complaints.cloudfunctions.net/sendEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ to, subject, html: htmlContent }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.text();
      console.log('Email sent successfully:', result);
    } catch (e) {
      console.error('Error sending email:', e);
      error.value = e.message;
    } finally {
      isSending.value = false;
    }
  };

  return {
    sendEmail,
    isSending,
    error,
  };
}
