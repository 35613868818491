// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const authConfig = {
    apiKey: "AIzaSyCJn3h5MELpX5UQS3MlWmWVTCgkp_FfDnk",
    authDomain: "imab-auth.firebaseapp.com",
    projectId: "imab-auth",
    storageBucket: "imab-auth.appspot.com",
    messagingSenderId: "96774304840",
    appId: "1:96774304840:web:7189c0a079ba513077547d",
    measurementId: "G-2C5FVQY1M6"
};

const dbConfig = {
  apiKey: "AIzaSyDd0-kufd4E7zBRDTdGsHD0VRKoH2SIAWY",
  authDomain: "imab-complaints.firebaseapp.com",
  projectId: "imab-complaints",
  storageBucket: "imab-complaints.appspot.com",
  messagingSenderId: "13127157446",
  appId: "1:13127157446:web:2b09586b2c97980d94b9c7",
  measurementId: "G-DERLL33JLP"
};

const authApp = initializeApp(authConfig, 'AUTH_APP');
const dbApp = initializeApp(dbConfig, 'DB_APP');

const auth = getAuth(authApp);
const db = getFirestore(dbApp);

export { auth, db };