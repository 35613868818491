<template>
  <div class="surface-0">
      <div class="flex mb-2">
          <div class="flex-0 flex align-items-center justify-content-center">
            <div class="px-0 py-2">
              <h2 class="m-0 p-0 font-light text-600">Employees</h2>
              <p class="p-0 m-0 pt-1 text-xs text-400"> Showing {{ filteredEmployees.length }} results.</p>
            </div>
          </div> 
          <div class="flex-1 flex align-items-center justify-content-end">
            <!-- Add Edit button here -->
            <Button v-if="selectedEmployeeRow" icon="pi pi-pencil" label="Edit" @click="showEditDialog" class="mr-2" />
            
            <!-- Add search field for employee names -->
            <InputText v-model="searchQuery" placeholder="Search Employee Name" class="mr-2" />

            <Dropdown v-model="selectedCompany" :options="companiesList" :loading="employeesListLoading" filter showClear 
                      :disabled="isRefreshing" optionLabel="company_id" placeholder="All Companies" class="w-full md:w-16rem mr-2">
              <template #value="slotProps">
                  <div v-if="slotProps.value" class="flex align-items-center">
                      {{ slotProps.value.company_name }}
                  </div>
                  <span v-else class="text-sm text-300">
                      {{ slotProps.placeholder }}
                  </span>
              </template>
              <template #option="slotProps">
                  <div class="flex align-items-center">
                      {{ slotProps.option.company_name }}
                  </div>
              </template>
            </Dropdown>
            <Dropdown v-model="selectedType" :options="typeList" :disabled="isRefreshing" optionLabel="name" placeholder="Status" class="w-full md:w-11rem mr-2">
              <template #value="slotProps">
                  <div v-if="slotProps.value" class="flex align-items-center">
                      {{ slotProps.value.name }}
                  </div>
                  <span v-else class="text-sm text-300">
                      {{ slotProps.placeholder }}
                  </span>
              </template>
            </Dropdown>
            <Dropdown v-model="selectedDepartment" :options="departmentList" :disabled="isRefreshing" optionLabel="name" placeholder="Department" class="w-full md:w-14rem mr-2">
              <template #value="slotProps">
                  <div v-if="slotProps.value" class="flex align-items-center">
                      {{ slotProps.value.name }}
                  </div>
                  <span v-else class="text-sm text-300">
                      {{ slotProps.placeholder }}
                  </span>
              </template>
            </Dropdown>          
            <Button icon="pi pi-plus" label="Add Employee" class="surface-300 hover:surface-500 mr-2" @click="showAddDialog" />
            <Button icon="pi pi-refresh" :loading="isRefreshing" @click="refreshData" class="surface-300 hover:surface-500" />
        </div>
      </div>
    </div>
  <div v-if="filteredEmployees && filteredEmployees.length > 0">
    <DataTable :value="paginatedEmployyes" selectionMode="single" v-model:selection="selectedEmployeeRow" :sortField="sortField" :sortOrder="sortOrder" :loading="isRefreshing">
      <Column field="employee_name" header="Employee" class="text-600 w-2">
          <template #body="slotProps">
              <div class="flex items-center gap-2">
                  <img alt="flag" :src="defaultUserImage" class="max-w-3rem max-h-3rem employee-photo	" />
                  <div>
                    <span class="block font-bold pb-1">{{ slotProps.data.employee_name }}</span>
                    <span class="block text-xs text-300">{{ slotProps.data.employee_company_name }}</span>
                    <span class="text-xs text-300 mr-2">
                      <span class="mr-2">@{{ slotProps.data.employee_id }}</span>
                    </span>
                  </div>
              </div>
          </template>
        </Column>
      <Column field="employee_department" header="Department" class="text-600 w-1">
        <template #body="slotProps">
            <div class=" items-center gap-2">
                  <span class="block pb-1">{{ slotProps.data.employee_department }}</span>
                  <span class="block text-xs text-300">{{ slotProps.data.employee_status }}</span>
            </div>
        </template>
      </Column>
      <Column field="employee_join_date" header="Joined" class="text-600">
        <template #body="slotProps">
            <div class=" items-center gap-2">
                  <span class="block pb-1">{{ slotProps.data.employee_join_date }}</span>
                  <span class="block text-xs text-300">{{ slotProps.data.employee_contract_date }}</span>
            </div>
        </template>
      </Column>
      <Column field="employee_badge_id" header="Badge" class="text-600">
        <template #body="slotProps">
            <div class=" items-center gap-2">
                  <span class="block pb-1">Badge #{{ slotProps.data.employee_badge_id }}</span>
                  <span class="block text-xs text-300">Pin #{{ slotProps.data.employee_badge_pin }}</span>
            </div>
        </template>
      </Column>
      <Column field="employee_contract" header="Contract" class="text-600">
        <template #body="slotProps">
            <div class=" items-center gap-2">
                  <span class="block pb-1">{{ slotProps.data.employee_contract }}</span>
                  <span class="block text-xs text-300">{{ slotProps.data.employee_extra_bonuses }}</span>
            </div>
        </template>
      </Column>
      <Column field="employee_email" header="Email" class="text-600">
        <template #body="slotProps">
            <div class=" items-center gap-2">
                  <span class="block pb-1">{{ slotProps.data.employee_email }}</span>
                  <span class="block text-xs text-300">{{ slotProps.data.employee_phone }}</span>
            </div>
        </template>
      </Column>

    </DataTable>
    <Paginator :rows="rows" :totalRecords="filteredEmployees.length"  v-model:first="first" :rowsPerPageOptions="[100, 200, 300]" @page="onPage"></Paginator>
    </div>
    <div v-else class="no-data-message">
      No Attendance found for this filter.
    </div>
    
    <!-- Add Dialog component -->
    <Dialog v-model:visible="editEmployeeDialog" :style="{ width: '60vw' }" :modal="true">
      <template #header>
        <h2>Edit Employee</h2>
      </template>
      <EmployeeUpdateDialog v-if="selectedEmployeeRow" :selectedId="selectedEmployeeRow.employee_id" @handleBack="handleBack" @employeeUpdated="handleEmployeeUpdated" />
    </Dialog>
    <Dialog v-model:visible="addEmployeeDialog" :style="{ width: '60vw' }" :modal="true">
      <template #header>
        <h2>Add Employee</h2>
      </template>
      <EmployeeAddDialog @handleBack="handleBack" @employeeCreated="handleEmployeeUpdated" />
    </Dialog> 
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import { useEmployees } from '@/composables/api/useEmployees';
import { useToast } from 'primevue/usetoast';
import { useClearEmployeeData } from '@/composables/useClearEmployeeData';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Paginator from 'primevue/paginator';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext'; // Add this import
import EmployeeUpdateDialog from '@/components/dialogs/EmployeeUpdateDialog.vue';
import EmployeeAddDialog from '@/components/dialogs/EmployeeAddDialog.vue';
import defaultUserImage from '@/assets/default-user.png';

const { employeesList, companiesList, employeesListLoading, fetchEmployees, initializeFromLocalStorage } = useEmployees();
const { clearEmployeeData } = useClearEmployeeData();

const toast = useToast();
const selectedEmployeeRow = ref(null);
const sortField = ref('employee_name');
const sortOrder = ref(1);
const rows = ref(100);
const first = ref(0);
const selectedEmployee = ref(null);
const selectedCompany = ref(null);
const isRefreshing = ref(false);
const selectedType = ref({ name: 'Active', code: 'Active' });
const typeList = [   
  { name: 'Active', code: 'Active' },
  { name: 'Permanent', code: 'Permanent' },
  { name: 'Probation', code: 'Probation' },
  { name: 'Trainee', code: 'Trainee' },
  { name: 'Resigned', code: 'Resigned' },
  { name: 'Terminated', code: 'Terminated' }
];

const selectedDepartment = ref({ name: 'All', code: 'All' });
const departmentList = [
  { name: 'All', code: 'All' },
  { name: 'CSR Staff', code: 'HR / Operator' },
  { name: 'Covering Staff', code: 'HR / Shift Cover' },
  { name: 'House keeping', code: 'House Keeping' },
  { name: 'Tech Staff', code: 'Tech' },
  { name: 'Manager', code: 'Manager' },
  { name: 'Maintenance', code: 'Maintenance' }
];

const editEmployeeDialog = ref(false);
const addEmployeeDialog = ref(false);

const searchQuery = ref(''); // Add this line for the search query

const refreshData = async () => {
  isRefreshing.value = true;
  try {
      await fetchEmployees();
  } catch (err) {
    handleError(err, 'Fetching Attendance');
  } finally {
    isRefreshing.value = false;
  }
};

// Filtered rosters based on dropdown selections
const filteredEmployees = computed(() => {
  if (!employeesList.value || !Array.isArray(employeesList.value)) {
    console.warn('employeesList.value is not an array:', employeesList.value);
    return [];
  }

  return employeesList.value.filter(employee => {
    const companyMatch = !selectedCompany.value || employee.employee_company_id === selectedCompany.value.company_id;
    
    const departmentMatch = !selectedDepartment.value || 
                            selectedDepartment.value.code === 'All' || 
                            employee.employee_department === selectedDepartment.value.code;

    const statusMatch = !selectedType.value || 
                        (selectedType.value.code === 'Active' && 
                         (employee.employee_status === 'Permanent' || employee.employee_status === 'Probation')) ||
                        employee.employee_status === selectedType.value.code;

    const nameMatch = !searchQuery.value || 
                      employee.employee_name.toLowerCase().includes(searchQuery.value.toLowerCase());

    return companyMatch && departmentMatch && statusMatch && nameMatch;
  });
});

// Paginated rosters
const paginatedEmployyes = computed(() => {
  const start = first.value;
  const end = start + rows.value;
  return filteredEmployees.value.slice(start, end);
});

// Methods
const onPage = (event) => {
  first.value = event.first;
  rows.value = event.rows;
};

// Watch for changes in selectedCompany
watch(selectedCompany, () => {
  selectedEmployee.value = null;
});

// Watch for changes in selectedEmployee
watch(selectedEmployee, async (newValue) => {
  try {
    isRefreshing.value = true;
    if (newValue) {
      await fetchEmployees(newValue.employee_id, 0);
    } else {
      await fetchEmployees();
    }
  } catch (error) {
    handleError(error, 'Updating Selected Employee');
  } finally {
    isRefreshing.value = false;
  }
});

// Watch for changes in dropdown selections
watch([selectedEmployee, selectedCompany, selectedType, selectedDepartment, searchQuery], () => {
  first.value = 0; 
});

// Handle Errors
const handleError = (error, context) => {
  console.error(`Error in ${context}:`, error);
  
  let errorMessage = 'An unexpected error occurred.';
  if (error instanceof Error) {
    errorMessage = error.message;
  } else if (typeof error === 'string') {
    errorMessage = error;
  }

  toast.add({
    severity: 'error',
    summary: `Error: ${context}`,
    detail: errorMessage,
    life: 12000
  });
};

const showEditDialog = () => {
  editEmployeeDialog.value = true;
};

const showAddDialog = () => {
  addEmployeeDialog.value = true;
};

const handleBack = () => {
  editEmployeeDialog.value = false;
};

const handleEmployeeUpdated = async () => {
  try {
    // Close the edit/create dialog
    editEmployeeDialog.value = false;
    addEmployeeDialog.value = false;

    // Clear employee data from local storage
    clearEmployeeData();

    // Fetch the latest data
    await fetchEmployees();

    // Initialize from local storage (this will update the reactive refs)
    initializeFromLocalStorage();

    // Refresh the data in the current component
    await refreshData();

    toast.add({
      severity: 'success',
      summary: 'Data Updated',
      detail: 'Employee data has been successfully updated and refreshed.',
      life: 3000
    });
  } catch (error) {
    handleError(error, 'Updating Employee Data');
  }
};

onMounted(async () => {
  try {
    await refreshData();
  } catch (error) {
    handleError(error, 'Component Initialization');
  }
});

</script>