<template>
    <div>
        <div class="flex mb-2">
            <div class="flex-0 flex align-items-center justify-content-center">
              <div class="px-0 py-2">
                <h2 class="m-0 p-0 font-light text-600">Profiles</h2>
                <p class="p-0 m-0 pt-1 text-xs text-400"> Showing {{ filteredEmployees.length }} results.</p>
              </div>
            </div>
            <div class="flex-1 flex align-items-center gap-2 justify-content-end">
                <!-- Add search field for employee names -->
                <InputText v-model="searchQuery" placeholder="Search Employee Name" class="w-full md:w-16rem" />

                <Dropdown v-model="selectedCompany" :options="companiesList" filter showClear optionLabel="company_name" placeholder="All Companies" 
                class="w-full md:w-16rem" @change="onCompanyChange" @clear="onCompanyClear">
                    <template #value="slotProps">
                        <div v-if="slotProps.value" class="flex align-items-center">
                            {{ slotProps.value.company_name }}
                        </div>
                        <span v-else class="text-sm text-300">
                            {{ slotProps.placeholder }}
                        </span>
                    </template>
                    <template #option="slotProps">
                        <div class="flex align-items-center">
                            {{ slotProps.option.company_name }}
                        </div>
                    </template>
                </Dropdown>
            </div>
        </div>
      </div>
    <div class="flex">
        <div class="flex mr-2">
            <div>
                <DataTable :value="searchFilteredEmployees" selectionMode="single" v-model:selection="selectedEmployee"
                @row-select="onEmployeeSelect" scrollable scrollHeight="860px" tableStyle="min-width: 16rem">
                <Column field="employee_name" header="Employee" class="text-600">
                    <template #body="slotProps">
                        <div class="flex items-center gap-2 relative">
                            <img alt="flag" :src="defaultUserImage" class="max-w-3rem max-h-3rem employee-photo" />
                            <div>
                                <span class="block font-bold pb-1">{{ slotProps.data.employee_name }}</span>
                                <span class="block text-xs text-300">{{ slotProps.data.employee_company_name }}</span>
                                <span class="text-xs text-300 mr-2">
                                <span class="mr-2">@{{ slotProps.data.employee_id }}</span>
                                <span v-if="selectedEmployee && slotProps.data.employee_id === selectedEmployee.employee_id" 
                                    class="absolute bottom-0 right-0 px-2 py-1 border-round-sm surface-100 text-400">
                                    Selected
                                </span>
                                </span>
                            </div>
                        </div>
                    </template>
                    </Column>
                    <template #empty>
                        <div class="text-500 text-sm">No Employee found for this filter.</div>
                    </template>
                </DataTable>
            </div>
        </div>
        <div class="flex w-full profile-view">
            <TabView v-if="selectedCompany">
                <TabPanel header="Roster">
                    <ViewRoster 
                        :targetEmployeeId="selectedEmployee?.employee_id || 0" 
                        :targetCompanyId="selectedCompany?.company_id || 0" 
                        :targetDateList="'THISMONTH'"
                        /> 
                </TabPanel>
                <TabPanel header="Attendance">
                    <ViewAttendance 
                        :targetEmployeeId="selectedEmployee?.employee_id || 0" 
                        :targetCompanyId="selectedCompany?.company_id || 0" 
                        :targetDateList="'THISMONTH'"
                        /> 
                </TabPanel>
                <TabPanel header="Team Schedule">
                    <ViewSchedule 
                        :targetCompanyId="selectedCompany?.company_id || 0" 
                        :targetCompanyName="selectedCompany?.company_name || ''"
                        />
                    </TabPanel>
                <TabPanel header="Logs">
                    <ViewLogs :targetEmployeeId="selectedEmployee?.employee_id || 0" />
                </TabPanel>
                <TabPanel header="Complaints" :disabled="true"></TabPanel>
                <TabPanel header="Contract" :disabled="true"></TabPanel>
                <TabPanel header="Payroll" :disabled="true"></TabPanel>
            </TabView>
            <div v-else class="w-full">
                <div class="flex align-items-stretch flex-wrap" style="min-height: 100%;">
                    <div class="flex align-items-center justify-content-center surface-50 font-light text-300 text-2xl border-round m-2" style="min-width: 100%;">Please select a Company or an Employee.</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useUserSelections } from '@/composables/useUserSelections';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import ViewAttendance from '@/views/ViewAttendance.vue';
import ViewLogs from '@/views/ViewLogs.vue';
import ViewRoster from '@/views/ViewRoster.vue';
import ViewSchedule from '@/views/ViewSchedule.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import defaultUserImage from '@/assets/default-user.png';

const { 
  selectedCompany, 
  selectedEmployee, 
  filteredEmployees, 
  companiesList,
  onCompanyClear, 
  onCompanyChange, 
  onEmployeeSelect,
  fetchEmployees
} = useUserSelections();

const searchQuery = ref('');

// New computed property to filter employees based on search query
const searchFilteredEmployees = computed(() => {
  if (!searchQuery.value) {
    return filteredEmployees.value;
  }
  return filteredEmployees.value.filter(employee => 
    employee.employee_name.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
});

onMounted(async () => {
  try {
    await fetchEmployees();
  } catch (error) {
    console.error('Error fetching employees:', error);
  }
});
</script>

<style scoped>
.p-tabview{
    width: 100% !important;
}
</style>
<style>
.profile-view .p-tabview .p-tabview-nav li{
    margin-right: 8px;
}
</style>
