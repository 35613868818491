import { ref } from 'vue';
import axiosClient from '@/axiosClient';
import { API_ENDPOINTS } from '@/apiConfig';

export function useFetchUpcomingLeaves() {
    const data = ref(null);
    const error = ref(null);
    const loading = ref(false);

    function addLeaveDueTag(leaves) {
        const now = new Date();
        
        const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const todayEnd = new Date(todayStart);
        todayEnd.setHours(23, 59, 59, 999);

        const tomorrowStart = new Date(todayStart);
        tomorrowStart.setDate(tomorrowStart.getDate() + 1);
        const tomorrowEnd = new Date(tomorrowStart);
        tomorrowEnd.setHours(23, 59, 59, 999);

        const sevenDaysLater = new Date(todayStart);
        sevenDaysLater.setDate(sevenDaysLater.getDate() + 7);
        sevenDaysLater.setHours(23, 59, 59, 999);

        return leaves.map(leave => {
            const leaveDate = new Date(leave.leave_date);
            let leave_due;

            if (leaveDate >= todayStart && leaveDate <= todayEnd) {
                leave_due = "TODAY";
            } else if (leaveDate >= tomorrowStart && leaveDate <= tomorrowEnd) {
                leave_due = "TOMORROW";
            } else if (leaveDate > tomorrowEnd && leaveDate <= sevenDaysLater) {
                leave_due = "THISWEEK";
            } else if (leaveDate > sevenDaysLater) {
                leave_due = "IN30DAYS";
            } else {
                leave_due = "EXPIRED";
            }

            return { ...leave, leave_due };
        });
    }

    async function fetchUpcomingLeaves() {
        loading.value = true;
        try {
            const url = API_ENDPOINTS.fetchInsightUpcomingLeaves;
            
            const response = await axiosClient.get(url);
            if (response.data) {
                data.value = addLeaveDueTag(response.data);
                sessionStorage.setItem('upcomingLeaves', JSON.stringify(data.value));
            }
        } catch (err) {
            console.error('Error fetching upcoming leaves:', err);
            error.value = 'Failed to fetch upcoming leaves';
            if (axiosClient.isAxiosError(err)) {
                if (err.response) {
                    console.error('Error response:', err.response.data);
                    console.error('Error status:', err.response.status);
                    error.value = `Server error: ${err.response.status}`;
                } else if (err.request) {
                    console.error('Error request:', err.request);
                    error.value = 'No response from server';
                } else {
                    console.error('Error message:', err.message);
                    error.value = err.message;
                }
            }
        } finally {
            loading.value = false;
        }
    }

    return { data, error, loading, fetchUpcomingLeaves };
}
