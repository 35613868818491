<template>
    <div>
        <div class="flex mb-2">
            <div class="flex-0 flex align-items-center justify-content-center">
              <div class="px-0 py-2">
                <h2 class="m-0 p-0 font-light text-600">Complaints</h2>
                <p class="p-0 m-0 pt-1 text-xs text-400"> Showing {{ complaints.length }} results.</p>
              </div>
            </div>
            <div class="flex-1 flex align-items-center gap-2 justify-content-end">

                <InputText v-model="searchQuery" placeholder="Search Employee Name" class="w-full md:w-16rem" />
                <Button icon="pi pi-plus" label="New Complaint" @click="openNewComplaintDialog" />
            </div>
        </div>
    </div>
   <div class="flex complaints-view">
        <div class="flex-none mr-2 w-3">
            <DataTable :value="filteredComplaints" dataKey="id" v-model:selection="selectedComplaint" class="w-full" selectionMode="single" :loading="loading" @row-click="onRowClick">
                <Column field="employee.name" header="Employee">
                    <template #body="slotProps">
                            <div class="flex align-items-center gap-2 relative">
                                <img :alt="slotProps.data.employee.name" :src="defaultUserImage" class="w-3rem h-3rem" />
                                <div>
                                    <span class="font-bold block mb-1">{{ slotProps.data.employee.name }}</span>
                                    <span class="text-xs text-400 block">{{ slotProps.data.employee.employee_company_name }}</span>
                                    <span class="text-xs text-400">@{{ slotProps.data.employee.id }}</span>
                                    <span v-if="selectedComplaint && slotProps.data.employee.id === selectedComplaint.employee.id" 
                                        class="absolute top-0 right-0 px-2 py-1 text-xs bg-gray-700 text-400 border-round-sm">
                                        Selected
                                    </span>
                                    <span v-if="slotProps.data.status === 'Open'" class="absolute bottom-0 right-0 px-2 py-1 text-xs bg-green-800 text-50 border-round-sm">
                                        Open
                                    </span>
                                    <span v-if="slotProps.data.status === 'Closed'" class="absolute bottom-0 right-0 px-2 py-1 text-xs bg-gray-700 text-400 border-round-sm">
                                        Closed
                                    </span>

                                </div>
                            </div>
                        </template>
                 </Column>
                <template #empty>
                    <div class="text-500 text-sm">No Complaint found for this filter.</div>
                </template>
            </DataTable>   
        </div>
        <div class="flex-grow-1">
            <TabView v-if="selectedComplaint" class="w-full">
                <TabPanel header="Complaint Details">
                    <div class="p-1">
                        <div class="flex justify-content-between align-items-center mb-3">
                            <div>
                                <h2 class="text-lg m-0 p-0 mb-3 text-400 font-light">Complaint #{{ selectedComplaint.id }}</h2>
                                <span class="text-2xl font-light">{{ selectedComplaint.employee.name }}</span>
                                <span class="text-sm text-gray-400 block py-1">{{ selectedComplaint.employee.email }}</span>
                                <p class="text-300 m-0 p-0 text-sm">{{ formatFirebaseTimestamp(selectedComplaint.createdby.timestamp) }}</p>
                            </div>
                            <div class="text-right flex gap-2">
                                <p class="mb-2">
                                    <span :class="{
                                        'px-2 py-1 border-round text-sm': true,
                                        'bg-green-800 text-gray-900': selectedComplaint.status === 'Open',
                                        'bg-gray-500 text-gray-900': selectedComplaint.status === 'Closed'
                                    }">
                                        {{ selectedComplaint.status }}
                                    </span>
                                </p>
                                <p>
                                    <span class="px-2 py-1 border-round text-sm bg-gray-500 text-gray-900">
                                        {{ selectedComplaint.priority }}
                                    </span>
                                </p>
                            </div>
                        </div>

                        <div class="flex flex-column gap-3">
                            <div v-for="(post, index) in selectedComplaint.posts" :key="index" 
                                 :class="[
                                     'w-full p-3 border-round border-3',
                                     {
                                         'border-yellow-900': post.type === 'Initial',
                                         'border-pink-600': post.type === 'Reconcile',
                                         'border-green-900': post.type === 'Close' && post.deduction === 0 && post.score === 0,
                                         'border-red-800': post.type === 'Close' && post.deduction > 0 && post.score === 0,
                                         'border-yellow-800': post.type === 'Close' && post.deduction === 0 && post.score > 0,
                                         'border-red-500': post.type === 'Close' && post.deduction > 0 && post.score > 0
                                     }
                                 ]">
                                <div class="flex">
                                    <div class="flex-grow-1 pr-3"> <!-- This will grow to fill available space -->
                                        <div :class="[
                                            'w-8rem text-xs py-1 px-2 text-left border-round mb-2',
                                            {
                                                'bg-yellow-900 text-500': post.type === 'Initial',
                                                'bg-pink-600': post.type === 'Reconcile',
                                                'bg-green-900': post.type === 'Close' && post.deduction === 0 && post.score === 0,
                                                'bg-red-800': post.type === 'Close' && post.deduction > 0 && post.score === 0,
                                                'bg-yellow-800': post.type === 'Close' && post.deduction === 0 && post.score > 0,
                                                'bg-red-500': post.type === 'Close' && post.deduction > 0 && post.score > 0
                                            }
                                        ]">
                                        {{ post.type }} Message</div>
                                        <span class="text-400 text-xs block mb-2">by {{ post.email || selectedComplaint.createdby.email }} <span class="text-gray-500 px-2">//</span> {{ formatFirebaseTimestamp(post.timestamp) }}</span>
                                        <p class="line-height-3 m-0 p-0 mt-2 text-2xl">{{ post.message }}</p>
                                    </div>
                                    <div v-if="post.type === 'Close'" class="flex-none flex flex-column justify-content-center">
                                        <span v-if="post.deduction !== undefined" 
                                              :class="['mb-2 px-3 py-1 text-black border-round', 
                                                       post.deduction === 0 ? 'bg-gray-700' : 'bg-red-600']">
                                            Deduction: <strong>{{ post.deduction }}</strong>
                                        </span>
                                        <span v-if="post.score !== undefined" 
                                              :class="['px-3 py-1 text-black border-round', 
                                                       post.score === 0 ? 'bg-gray-700' : 'bg-yellow-600']">
                                            Score: <strong>{{ post.score }}</strong>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Move the Reconcile button here -->
                        <div v-if="selectedComplaint.status === 'Closed' && !showingReconcileForm" class="mb-3 mt-3">
                            <Button label="Reconcile" 
                                    icon="pi pi-refresh" 
                                    @click="showReconcileForm" 
                                    class="p-button-sm" />
                        </div>
                        
                        <div v-if="selectedComplaint.status === 'Open'" class="mt-4 p-3 border-3 border-200 border-round bg-gray-800">
                            <h3 class="text-2xl text-gray-400 font-light mb-3 mt-0 pt-0">Close Ticket</h3>
                            <div class="formgrid grid">
                                <div class="field col-12">
                                    <label for="closeMessage" class="block mb-2">Message</label>
                                    <Textarea id="closeMessage" v-model="closeTicketForm.message" rows="3" class="w-full" :disabled="isClosingTicket || !canCloseTicket" />
                                </div>
                                <div class="field col-6">
                                    <label for="deduction" class="block mb-2">Deduction</label>
                                    <InputNumber id="deduction" v-model="closeTicketForm.deduction" :min="0" class="w-full" :disabled="isClosingTicket || !canCloseTicket" />
                                </div>
                                <div class="field col-6">
                                    <label for="score" class="block mb-2">Score</label>
                                    <InputNumber id="score" v-model="closeTicketForm.score" class="w-full" :disabled="isClosingTicket || !canCloseTicket" />
                                </div>
                                <div class="col-12">
                                    <Button label="Close Ticket" icon="pi pi-check" @click="closeTicket" class="mt-3" :loading="isClosingTicket" :disabled="isClosingTicket || !canCloseTicket" />
                                </div>
                            </div>
                        </div>

                        <!-- Add this new form for reconciliation -->
                        <div v-if="showingReconcileForm" class="mt-4 p-3 border-3 border-200 border-round bg-gray-800">
                            <h3 class="text-2xl text-gray-400 font-light mb-3 mt-0 pt-0">Reconcile Ticket</h3>
                            <div class="formgrid grid">
                                <div class="field col-12">
                                    <label for="reconcileMessage" class="block mb-2">Message</label>
                                    <Textarea id="reconcileMessage" v-model="reconcileForm.message" rows="3" class="w-full" :disabled="isReconcilingTicket" />
                                </div>
                                <div class="col-12">
                                    <Button label="Reconcile Ticket" icon="pi pi-check" @click="reconcileTicket" class="mt-3" :loading="isReconcilingTicket" :disabled="isReconcilingTicket" />
                                    <Button label="Cancel" icon="pi pi-times" @click="cancelReconcile" class="mt-3 ml-2 p-button-secondary" :disabled="isReconcilingTicket" />
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Complaint History" :disabled="true"></TabPanel>
                <TabPanel header="Attendance Statistics" :disabled="true"></TabPanel>
                <TabPanel header="Payroll Statistics" :disabled="true"></TabPanel>
            </TabView>
            <div v-else class="w-full">
                <div class="flex align-items-center justify-content-center bg-gray-800 text-400 text-2xl border-round m-2" style="min-height: 200px;">
                    Please select a Complaint.
                </div>
            </div>
        </div>    
    </div>

    <Dialog v-model:visible="displayComplaintDialog" header="New Complaint" :style="{ width: '50vw' }" :modal="true">
      <div class="formgrid grid">
        <div class="field col-12">
          <label for="employee" class="block mb-2">Employee *</label>
          <Dropdown id="employee" v-model="newComplaint.employee" :options="employeesList" optionLabel="employee_name" placeholder="Select an Employee" :loading="employeesListLoading" class="w-full" :disabled="isSubmittingComplaint" />
        </div>
        <div class="field col-12">
          <label for="message" class="block mb-2">Message *</label>
          <Textarea id="message" v-model="newComplaint.message" rows="5" class="w-full" :disabled="isSubmittingComplaint" />
        </div>
        <div class="field col-12">
          <label for="priority" class="block mb-2">Priority *</label>
          <Dropdown id="priority" v-model="newComplaint.priority" :options="priorities" placeholder="Select Priority" class="w-full" :disabled="isSubmittingComplaint" />
        </div>
      </div>
      <template #footer>
        <div class="flex justify-content-between align-items-center w-full">
          <span>
            <span v-if="newComplaint.employee" class="text-gray-400">
              <div class="flex align-items-l">
                <div class="flex">
                  <i class="pi pi-envelope mr-2 text-2xl"></i>
                </div>
                <div class="flex flex-column text-left">
                  <span class="text-xs text-gray-500 block">Email will be sent to the employee</span>
                  <span class="text-sm text-gray-400 block">{{ newComplaint.employee.employee_email }}</span>
                </div>
              </div>
            </span>
            <span v-else class="text-sm text-gray-600">
              Select an employee to see its email address here.
            </span>
          </span>
          <div>
            <Button label="Cancel" class="bg-gray-600 hover:bg-gray-400" @click="closeNewComplaintDialog" :disabled="isSubmittingComplaint" />
            <Button label="Submit" icon="pi pi-check" @click="submitComplaint" :disabled="!isFormValid" :loading="isSubmittingComplaint" autofocus />
          </div>
        </div>
      </template>
    </Dialog>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed, watch } from 'vue';
import { auth } from '@/firebaseConfig'; 
import { firestoreDB } from '@/firestore';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import InputNumber from 'primevue/inputnumber';
import { useEmployees } from '@/composables/api/useEmployees';
import defaultUserImage from '@/assets/default-user.png';
import { Timestamp } from 'firebase/firestore';  // Add this import
import { useEmailSender } from '@/composables/emails/useEmailSender';

const complaints = ref([]);
const searchQuery = ref('');
const loading = ref(true);
const displayComplaintDialog = ref(false);
const newComplaint = ref({
  employee: null,
  message: '',
  priority: 'Low' // Set default priority to 'Low'
});

const priorities = ['Low', 'Medium', 'High'];

const { employeesList, employeesListLoading, fetchEmployees, initializeFromLocalStorage } = useEmployees();
const { sendEmail, error: emailError } = useEmailSender();

const filteredComplaints = computed(() => {
    let filtered = complaints.value;
    
    if (searchQuery.value) {
        const lowercaseQuery = searchQuery.value.toLowerCase();
        filtered = filtered.filter(complaint => 
            complaint.employee.name.toLowerCase().includes(lowercaseQuery)
        );
    }
    
    // Sort the complaints based on status and then by most recent post timestamp
    return filtered.sort((a, b) => {
        const statusOrder = { 'Open': 0, 'Reconcile': 1, 'Closed': 2 };
        
        // First, sort by status
        const statusComparison = statusOrder[a.status] - statusOrder[b.status];
        
        if (statusComparison !== 0) {
            return statusComparison;
        }
        
        // If status is the same, sort by most recent post timestamp
        const aLatestTimestamp = Math.max(...a.posts.map(post => post.timestamp.toMillis()));
        const bLatestTimestamp = Math.max(...b.posts.map(post => post.timestamp.toMillis()));
        
        // Sort in descending order (most recent first)
        return bLatestTimestamp - aLatestTimestamp;
    });
});

const isSubmittingComplaint = ref(false);

const isFormValid = computed(() => {
  return newComplaint.value.employee &&
         newComplaint.value.message.trim() !== '' &&
         newComplaint.value.priority;
});

const openNewComplaintDialog = () => {
  displayComplaintDialog.value = true;
};

const closeNewComplaintDialog = () => {
  displayComplaintDialog.value = false;
  newComplaint.value = { employee: null, message: '', priority: 'Low' };
  isSubmittingComplaint.value = false;
};

const submitComplaint = async () => {
  try {
    isSubmittingComplaint.value = true;
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error('User not authenticated');
    }

    if (!newComplaint.value.employee) {
      throw new Error('Employee must be selected');
    }

    const complaintData = {
      priority: newComplaint.value.priority,
      status: 'Open',
      employee: {
        id: newComplaint.value.employee.employee_id,
        name: newComplaint.value.employee.employee_name,
        employee_company_id: newComplaint.value.employee.employee_company_id,
        employee_company_name: newComplaint.value.employee.employee_company_name,
        email: newComplaint.value.employee.employee_email
      },
      posts: [
        {
          type: 'Initial',
          message: newComplaint.value.message,
          status: 'Open',
          timestamp: Timestamp.now()
        }
      ],
      createdby: {
        id: currentUser.uid,
        name: currentUser.displayName || 'Unknown',
        email: currentUser.email,
        timestamp: Timestamp.now()
      }
    };

    // Add the new complaint to Firestore and get the document reference
    const docRef = await firestoreDB.addDocument('complaints', complaintData);

    // Create the new complaint object with the ID
    const newComplaintData = {
      id: docRef,
      ...complaintData
    };

    // Update the complaints list
    await fetchComplaints();
    
    // Set the selectedComplaint to the newly created complaint
    selectedComplaint.value = newComplaintData;
    
    // Send email notification
    await sendEmail(
      newComplaintData.employee.email || 'hussainbtk@gmail.com',
      'New Complaint Created #' + newComplaintData.id,
      newComplaintData
    );

    closeNewComplaintDialog();
  } catch (error) {
    console.error('Error submitting complaint:', error);
    // Handle error (e.g., show error message to user)
  } finally {
    isSubmittingComplaint.value = false;
  }
};

const fetchComplaints = async () => {
  try {
    loading.value = true;
    complaints.value = await firestoreDB.getDocuments('complaints');
  } catch (error) {
    console.error('Error fetching complaints:', error);
    // Handle error (e.g., show error message to user)
  } finally {
    loading.value = false;
  }
};

const selectedComplaint = ref(null);
const closeTicketForm = ref({
  message: '',
  deduction: 0,
  score: 0
});

const isClosingTicket = ref(false);

const onRowClick = (event) => {
  selectedComplaint.value = event.data;
};

const closeTicket = async () => {
  try {
    if (!selectedComplaint.value || !selectedComplaint.value.id) {
      console.error('No complaint selected or invalid complaint ID');
      return;
    }
    
    isClosingTicket.value = true;

    const updatedData = {
      status: 'Closed',
      posts: [
        ...selectedComplaint.value.posts,
        {
          type: 'Close',
          message: closeTicketForm.value.message,
          status: 'Closed',
          timestamp: Timestamp.now(),
          email: auth.currentUser.email,
          deduction: closeTicketForm.value.deduction,
          score: closeTicketForm.value.score
        }
      ]
    };

    // Log the document ID and updated data for debugging
    console.log('Updating document:', selectedComplaint.value.id, updatedData);

    await firestoreDB.updateDocument('complaints', selectedComplaint.value.id, updatedData);
    await fetchComplaints();
    
    // Update the selected complaint with the new data
    const updatedComplaint = complaints.value.find(c => c.id === selectedComplaint.value.id);
    if (updatedComplaint) {
      selectedComplaint.value = updatedComplaint;
    }

    // Send email notification for closed ticket
    await sendEmail(
      selectedComplaint.value.employee.email || 'hussainbtk@gmail.com',
      `Ticket #${selectedComplaint.value.id} Closed`,
      updatedComplaint
    );

    // Reset the form
    closeTicketForm.value = { message: '', deduction: 0, score: 0 };
  } catch (error) {
    console.error('Error closing ticket:', error);
    // Handle error (e.g., show error message to user)
  } finally {
    isClosingTicket.value = false;
  }
};

const fetchEmployeeData = async () => {
  try {
    await fetchEmployees();
    await initializeFromLocalStorage();
  } catch (error) {
    console.error('Error fetching employees:', error);
    // Handle error (e.g., show toast notification)
  }
};

const formatFirebaseTimestamp = (timestamp) => {
  if (timestamp instanceof Timestamp) {
    const date = timestamp.toDate();
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
  } else if (typeof timestamp === 'string') {
    // If the timestamp is stored as an ISO string
    return new Date(timestamp).toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
  }
  return 'Invalid Date';
};

// Add these new refs
const showingReconcileForm = ref(false);
const isReconcilingTicket = ref(false);
const reconcileForm = ref({
  message: '',
  deduction: 0,
  score: 0
});

const showReconcileForm = () => {
  showingReconcileForm.value = true;
};

const cancelReconcile = () => {
  showingReconcileForm.value = false;
  reconcileForm.value = { message: '', deduction: 0, score: 0 };
};

const reconcileTicket = async () => {
  try {
    if (!selectedComplaint.value) return;
    
    isReconcilingTicket.value = true;

    const updatedData = {
      status: 'Open', // Reopen the ticket
      posts: [
        ...selectedComplaint.value.posts,
        {
          type: 'Reconcile',
          message: reconcileForm.value.message,
          status: 'Open',
          timestamp: Timestamp.now(),
          email: auth.currentUser.email
        }
      ]
    };

    await firestoreDB.updateDocument('complaints', selectedComplaint.value.id, updatedData);
    await fetchComplaints();
    
    // Update the selected complaint with the new data
    const updatedComplaint = complaints.value.find(c => c.id === selectedComplaint.value.id);
    if (updatedComplaint) {
      selectedComplaint.value = updatedComplaint;
    }

    // Send email notification for reopened ticket
    await sendEmail(
      'hussainbtk@gmail.com',
      `Ticket #${selectedComplaint.value.id} Opened Again`,
      updatedComplaint
    );

    // Reset the form and hide it
    reconcileForm.value = { message: '', deduction: 0, score: 0 };
    showingReconcileForm.value = false;
  } catch (error) {
    console.error('Error reconciling ticket:', error);
    // Handle error (e.g., show error message to user)
  } finally {
    isReconcilingTicket.value = false;
  }
};

// Add this new computed property
const canCloseTicket = computed(() => {
  const currentUser = auth.currentUser;
  if (!currentUser) return false;
  
  const authorizedEmails = ['spring@theorion.pk', 'maverick@theorion.pk', 'apex@theorion.pk'];
  return authorizedEmails.includes(currentUser.email);
});

watch(emailError, (newError) => {
  if (newError) {
    // Show error notification to user
    console.error('Failed to send email notification:', newError);
  }
});

onMounted(async () => {
  const unsubscribe = auth.onAuthStateChanged((user) => {
    if (user) {
      fetchComplaints();
    } else {
      complaints.value = [];
    }
  });

  // Clean up the listener when the component is unmounted
  onUnmounted(() => unsubscribe());

  await fetchEmployeeData();

  // Initialize searchQuery
  searchQuery.value = '';
});
</script>

<style>
.complaints-view .p-tabview .p-tabview-nav li{
    margin-right: 8px;
}
</style>