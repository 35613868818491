<template>
  <div style="background-color: #e5e5e5; border: 10px solid #56a8ee; padding: 20px; font-family: Arial, sans-serif;">
    <div style="width: 100%;">
      <div style="display: inline-block; width: 80%; vertical-align: top;">
        <p style="font-size: 12px; color: #666; margin: 0 0 10px; padding: 0;">{{ formatDate(props.complaint.createdby.timestamp) }}</p>
        <p style="font-size: 14px; margin: 0; padding: 0; font-weight: bold;">Dear {{ props.complaint.employee.name }},</p>
        <p style="font-size: 14px; margin: 0; padding: 0;">New Ticket has been initiated.</p>
      </div>
      <div style="display: inline-block; width: 20%; vertical-align: top; text-align: right;">
        <img src="https://core.imab.app/img/orion-logo-white-bg.af5f5b39.png" alt="Orion Logo" style="max-width: 74px; height: auto;">
      </div>
    </div>
    <div v-for="(post, index) in sortedPosts" :key="index" :style="getPostStyle(post.type)">
        <p style="font-size: 12px; color: #666; margin: 0 0 10px; padding: 0;">{{ formatDate(post.timestamp) }}</p>
        <template v-if="post.type === 'Initial'">
            <h4 style="color: #777777; font-size: 14px; font-weight: bold; padding: 0px; margin: 0px; margin-bottom: 6px;">Initial Report</h4>
        </template>
        <template v-else-if="post.type === 'Close'">
            <h4 style="color: #777777; font-size: 14px; font-weight: bold; padding: 0px; margin: 0px; margin-bottom: 6px;">Final Report</h4>
        </template>
        <template v-else-if="post.type === 'Reconcile'">
            <h4 style="color: #777777; font-size: 14px; font-weight: bold; padding: 0px; margin: 0px; margin-bottom: 6px;">Ticket Opened Again</h4>
        </template>
        <p style="margin-bottom: 10px; font-size: 16px; padding: 0px; margin: 0px;">{{ post.message }}</p>
        <template v-if="post.type === 'Close'">
            <p v-if="post.deduction !== undefined" style="color: red; text-decoration: underline; font-size: 14px;"><strong>Deduction:</strong> {{ post.deduction }}</p>
            <p v-if="post.score !== undefined" style="color: red; text-decoration: underline; font-size: 14px;"><strong>Score:</strong> {{ post.score }}</p>
        </template>
    </div>

    <div style="margin-top: 10px; border-top: 1px solid #dddddd; padding-top: 5px;">
      <p style="font-style: italic; color: red;">Disclaimer: If there is any decrease in your score, it will impact your salary. The detailed breakdown can be found in your upcoming payslip.</p>
      <p>Thank you</p>
      <p>Best regards,</p>
      <p style="margin-bottom: 14px;"><strong>ORION OPERATIONS</strong><br>operations@theorion.pk</p>
      <p style="font-style: italic; color: #666; font-size: 10px;">Note: This is a system generated email. Please do not reply to this email.</p>
      <p style="font-style: italic; color: #666; font-size: 10px;">Confidentiality Notice: This email and any attachments are confidential and may be privileged. If you are not the intended recipient, please delete it and notify the sender immediately. Any unauthorized use, disclosure, or copying of this email is strictly prohibited.</p>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps({
  complaint: {
    type: Object,
    required: true
  }
});

const sortedPosts = computed(() => {
  return [...props.complaint.posts].sort((a, b) => b.timestamp.seconds - a.timestamp.seconds);
});

const formatDate = (timestamp) => {
  return new Date(timestamp.seconds * 1000).toLocaleString();
};

const getPostStyle = (type) => {
  const baseStyle = "background-color: #ffffff; border: 4px dashed; border-radius: 6px; padding: 15px; margin-bottom: 15px;";
  
  switch (type) {
    case 'Initial':
      return baseStyle + " border-color: #ff8a00;";
    case 'Close':
      return baseStyle + " border-color: red;";
    case 'Reconcile':
      return baseStyle + " border-color: #ff00ff;";
    default:
      return baseStyle + " border-color: #dddddd;";
  }
};
</script>

<style scoped>
/* Add your styles here */
</style>
