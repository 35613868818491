<template>
    <div class="relative">
        <form @submit.prevent="handleSubmit">
            <div class="grid">
                <div class="col-12 md:col-6">
                    <h3>Employee Info</h3>
                    <div class="grid">
                        <!-- Add employee_id field -->
                        <div class="col-12 mb-1">
                            <label for="employee_id" class="block text-xs text-gray-400 mb-1">Employee ID *</label>
                            <InputText id="employee_id" v-model="employee.employee_id" :disabled="isCreating" class="w-full" required />
                        </div>
                        <div class="col-12 mb-1">
                            <label for="employee_name" class="block text-xs text-gray-400 mb-1">Name *</label>
                            <InputText id="employee_name" v-model="employee.employee_name" :disabled="isCreating" class="w-full" required />
                        </div>
                        <div class="col-12 mb-1">
                            <label for="employee_email" class="block text-xs text-gray-400 mb-1">Email *</label>
                            <InputText id="employee_email" v-model="employee.employee_email" :disabled="isCreating" class="w-full" required />
                        </div>
                        <div class="col-12 mb-1">
                            <label for="employee_badge_id" class="block text-xs text-gray-400 mb-1">Badge ID *</label>
                            <InputText id="employee_badge_id" v-model="employee.employee_badge_id" :disabled="isCreating" class="w-full" required />
                        </div>
                        <div class="col-12 mb-1">
                            <label for="employee_badge_pin" class="block text-xs text-gray-400 mb-1">Badge PIN *</label>
                            <InputText id="employee_badge_pin" v-model="employee.employee_badge_pin" :disabled="isCreating" class="w-full" required />
                        </div>
                    </div>
                </div>

                <div class="col-12 md:col-6">
                    <h3>Contract Info</h3>
                    <div class="grid">
                        <div class="col-12 mb-2">
                            <label for="employee_status" class="block text-xs text-gray-400 mb-1">Status *</label>
                            <Dropdown id="employee_status" v-model="employee.employee_status" :options="statusOptions" :disabled="isCreating" optionLabel="name" optionValue="code" class="w-full" required />
                        </div>
                        <div class="col-12 mb-1">
                            <label for="employee_department" class="block text-xs text-gray-400 mb-1">Department *</label>
                            <Dropdown id="employee_department" v-model="employee.employee_department" :disabled="isCreating" :options="departmentOptions" optionLabel="name" optionValue="code" class="w-full" required />
                        </div>
                        <div class="col-12 mb-1">
                            <label for="employee_company_id" class="block text-xs text-gray-400 mb-1">Company *</label>
                            <Dropdown id="employee_company_id" v-model="employee.employee_company_id" :disabled="isCreating" :options="companiesList" optionLabel="company_name" optionValue="company_id" :loading="employeesListLoading" class="w-full" required />
                        </div>
                        <div class="col-12 mb-2">
                            <label for="employee_join_date" class="block text-xs text-gray-400 mb-1">Join Date *</label>
                            <Calendar id="employee_join_date" dateFormat="yy-mm-dd" v-model="employee.employee_join_date" :disabled="isCreating" class="w-full" required />
                        </div>
                        <div class="col-12 mb-2">
                            <label for="employee_contract_date" class="block text-xs text-gray-400 mb-1">Contract Date *</label>
                            <Calendar id="employee_contract_date" dateFormat="yy-mm-dd" v-model="employee.employee_contract_date" :disabled="isCreating" class="w-full" required />
                        </div>
                        <div class="col-12 mb-2">
                            <label for="employee_contract" class="block text-xs text-gray-400 mb-1">Contract *</label>
                            <InputText id="employee_contract" v-model="employee.employee_contract" disabled="true" class="w-full" required />
                        </div>
                        <div class="col-12 mb-2">
                            <label for="employee_extra_bonuses" class="block text-xs text-gray-400 mb-1">Extra Bonuses *</label>
                            <InputText id="employee_extra_bonuses" v-model="employee.employee_extra_bonuses" disabled="true" class="w-full" required />
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <footer class="flex overflow-hidden mt-3">
            <div class="flex-shrink-0 flex align-items-start justify-content-start mr-2 mt-1">
                <Button label="Cancel" :disabled="isCreating" class="surface-200 hover:surface-400" @click="handleBack" aria-label="Cancel and go back" />
            </div>
            <div class="flex-grow-1 flex align-items-start justify-content-end">
                <Button label="Add Employee" :disabled="isCreating" :loading="isCreating" class="w-full bg-primary hover:surface-900 px-3 mt-1" @click="handleSubmit" aria-label="Submit" />
            </div>
        </footer>
    </div>
</template>

<script setup>
import { ref, defineEmits } from 'vue';
import { useEmployees } from '@/composables/api/useEmployees';
import { useEmployeeSingleCreate } from '@/composables/api/useEmployeeSingleCreate';
import { useToast } from 'primevue/usetoast';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';

const emit = defineEmits(['handleBack', 'employeeCreated']);

const { companiesList, employeesListLoading } = useEmployees();
const { isCreating, createEmployee } = useEmployeeSingleCreate();
const toast = useToast();

const employee = ref({
    employee_id: '', // Add employee_id field
    employee_name: '',
    employee_email: '',
    employee_badge_id: '',
    employee_badge_pin: '',
    employee_status: '',
    employee_department: '',
    employee_company_id: null,
    employee_join_date: null,
    employee_contract_date: null,
    employee_contract: 'AUTOMATED',
    employee_extra_bonuses: 'NONE'
});

const statusOptions = [   
    { name: 'Permanent', code: 'Permanent' },
    { name: 'Probation', code: 'Probation' },
    { name: 'Trainee', code: 'Trainee' },
    { name: 'Resigned', code: 'Resigned' },
    { name: 'Terminated', code: 'Terminated' }
];

const departmentOptions = [   
    { name: 'CSR Staff', code: 'HR / Operator' },
    { name: 'Covering Staff', code: 'HR / Shift Cover' },
    { name: 'House keeping', code: 'House Keeping' },
    { name: 'Tech Staff', code: 'Tech' },
    { name: 'Manager', code: 'Manager' },
    { name: 'Maintenance', code: 'Maintenance' }
];

const handleBack = () => {
    emit('handleBack');
};

const handleSubmit = async () => {
    // Check if all fields are filled
    const requiredFields = Object.keys(employee.value).filter(field => field !== 'employee_id');
    const missingFields = requiredFields.filter(field => !employee.value[field]);

    if (missingFields.length > 0) {
        toast.add({
            severity: 'error',
            summary: 'Validation Error',
            detail: `Please fill in all required fields: ${missingFields.join(', ')}`,
            life: 5000,
            group: 'br'
        });
        return;
    }

    // Prepare the payload
    const payload = { ...employee.value };
    
    // Only include employee_id if it's greater than 0
    if (parseInt(payload.employee_id) <= 0 || payload.employee_id === '') {
        delete payload.employee_id;
    }

    try {
        const createdEmployee = await createEmployee(payload);
        toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Employee created successfully',
            life: 3000,
            group: 'br'
        });
        // Notify the parent component
        emit('employeeCreated', createdEmployee);
    } catch (err) {
        let errorMessage = 'An error occurred while creating the employee';
        if (err.response && err.response.data) {
            if (err.response.status === 409) {
                errorMessage = `${err.response.data.error} Field: ${err.response.data.field}`;
            } else {
                errorMessage = err.response.data.error || errorMessage;
            }
        }
        toast.add({
            severity: 'error',
            summary: 'Creation Failed',
            detail: errorMessage,
            life: 5000,
            group: 'br'
        });
    }
};
</script>