<template>
  <div>
    <div class="flex mb-0 surface-50 px-3 mb-2 border-round-sm">
      <div class="flex-0 flex align-items-center justify-content-center">
        <div class="px-0 py-2">
          <h1 class="m-0 p-0 text-3xl font-light text-600">Insight</h1>
          <p class="m-0 py-2 text-sm text-600">
            {{ new Date().toLocaleDateString('en-GB', { weekday: 'long', day: 'numeric', month: 'long' }) }}
          </p>
        </div>
      </div>

      <div class="flex-1 flex align-items-center justify-content-end">
        <Button icon="pi pi-refresh" :loading="loading" class="bg-gray-500 hover:bg-gray-300 text-black" @click="fetchData" />
      </div>
    </div>

    <div v-if="loading">Loading data...</div>
    <div v-else-if="error" class="text-red-500">{{ error }}</div>
    <div v-else class="grid">
      <div v-for="column in columns" :key="column" class="col-12 md:col-6 lg:col-3">
        <h2 class="text-2xl font-light uppercase letter-spacing-3 text-400 mb-3">{{ column }}</h2>
        <div v-if="groupedData[column].items.length > 0">
          <div v-for="item in groupedData[column].items" :key="item.employee_id" class="p-0 mb-3 border-round surface-card">
            <div class="flex align-items-stretch">
              <span :class="['type-avatar mr-2', item.type === 'LEAVE' ? 'leave-type' : 'roster-type']">
                {{ item.type === 'LEAVE' ? 'L' : 'R' }}
              </span>
              <div class="flex-grow-1 pt-2 pb-1">
                <h4 class="text-sm font-bold m-0 p-0">{{ item.employee_name }}</h4>
                <div class="flex justify-content-between align-items-center pr-2">
                  <p class="text-xs text-400 m-0 p-0">{{ item.employee_company_name }}</p>
                  <span :class="['text-xs', item.duration.toLowerCase()]">
                    {{ item.duration }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useFetchExpiringRosters } from '@/composables/api/insight/useFetchExpiringRosters';
import { useFetchUpcomingLeaves } from '@/composables/api/insight/useFetchUpcomingLeaves';
import Button from 'primevue/button';

const { 
  data: expiringRostersData, 
  error: expiringRostersError, 
  loading: expiringRostersLoading, 
  fetchExpiringRosters 
} = useFetchExpiringRosters();

const { 
  data: upcomingLeavesData, 
  error: upcomingLeavesError, 
  loading: upcomingLeavesLoading, 
  fetchUpcomingLeaves 
} = useFetchUpcomingLeaves();

const columns = ['Today', 'Tomorrow', 'This Week', 'In 30 Days'];

const loading = computed(() => expiringRostersLoading.value || upcomingLeavesLoading.value);
const error = computed(() => expiringRostersError.value || upcomingLeavesError.value);

const groupedData = computed(() => {
  const result = {};
  columns.forEach(column => {
    result[column] = { items: [] };
  });

  // First, add LEAVE data
  if (upcomingLeavesData.value) {
    upcomingLeavesData.value.forEach(leave => {
      result[mapDurationToColumn(leave.leave_due)].items.push({
        ...leave,
        type: 'LEAVE',
        duration: leave.leave_due
      });
    });
  }

  // Then, add ROSTER data
  if (expiringRostersData.value) {
    expiringRostersData.value.forEach(roster => {
      result[mapDurationToColumn(roster.expiring_duration)].items.push({
        ...roster,
        type: 'ROSTER',
        duration: roster.expiring_duration
      });
    });
  }

  return result;
});

function mapDurationToColumn(duration) {
  switch (duration.toLowerCase()) {
    case 'expired':
    case 'today': return 'Today';
    case 'tomorrow': return 'Tomorrow';
    case 'thisweek': return 'This Week';
    case 'nextweek':
    case 'in30days': return 'In 30 Days';
    default: return 'In 30 Days';
  }
}

function fetchData() {
  fetchExpiringRosters();
  fetchUpcomingLeaves();
}

onMounted(() => {
  fetchExpiringRosters();
  fetchUpcomingLeaves();
});
</script>

<style scoped>
.expired { background-color: var(--gray-900); color: var(--red-700); padding: 0.25rem 0.5rem; border-radius: 0.25rem; }
.today { background-color: var(--gray-900); color: var(--orange-500); padding: 0.25rem 0.5rem; border-radius: 0.25rem; }
.tomorrow { background-color: var(--gray-900); color: var(--yellow-800); padding: 0.25rem 0.5rem; border-radius: 0.25rem; }
.thisweek { background-color: var(--gray-900); color: var(--gray-500); padding: 0.25rem 0.5rem; border-radius: 0.25rem; }
.nextweek { background-color: var(--gray-900); color: var(--gray-500); padding: 0.25rem 0.5rem; border-radius: 0.25rem; }
.in30days { background-color: var(--gray-900); color: var(--gray-500); padding: 0.25rem 0.5rem; border-radius: 0.25rem; }
.leave-type { background-color: var(--yellow-800); color: black; padding: 0.25rem 0.5rem; border-radius: 0.25rem; }
.roster-type { background-color: var(--gray-600); color: black; padding: 0.25rem 0.5rem; border-radius: 0.25rem; }
.type-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem 0 0 0.25rem;
  font-weight: bold;
  font-size: 0.8rem;
  white-space: nowrap;
}
</style>