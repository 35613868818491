import { db, auth } from './firebaseConfig';
import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc } from 'firebase/firestore';

export const firestoreDB = {
  async addDocument(collectionName, data) {
    try {
      // Ensure the user is authenticated before adding a document
      if (!auth.currentUser) {
        throw new Error('User not authenticated');
      }
      const docRef = await addDoc(collection(db, collectionName), data);
      console.log("Document written with ID: ", docRef.id);
      return docRef.id;
    } catch (e) {
      console.error("Error adding document: ", e);
      throw e;
    }
  },

  async getDocuments(collectionName) {
    try {
      // Ensure the user is authenticated before getting documents
      if (!auth.currentUser) {
        throw new Error('User not authenticated');
      }
      const querySnapshot = await getDocs(collection(db, collectionName));
      return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (e) {
      console.error("Error getting documents: ", e);
      throw e;
    }
  },

  async updateDocument(collectionName, docId, data) {
    try {
      if (!auth.currentUser) {
        throw new Error('User not authenticated');
      }
      const docRef = doc(db, collectionName, docId);
      await updateDoc(docRef, data);
      console.log("Document updated successfully");
    } catch (e) {
      console.error("Error updating document: ", e);
      throw e;
    }
  },

  async deleteDocument(collectionName, docId) {
    try {
      // Ensure the user is authenticated before deleting a document
      if (!auth.currentUser) {
        throw new Error('User not authenticated');
      }
      await deleteDoc(doc(db, collectionName, docId));
      console.log("Document deleted successfully");
    } catch (e) {
      console.error("Error deleting document: ", e);
      throw e;
    }
  }
};
