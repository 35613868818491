import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import ViewAttendance from '../views/ViewAttendance.vue';
import ViewEmployees from '../views/ViewEmployees.vue';
import ViewRoster from '../views/ViewRoster.vue';
import ViewSchedule from '../views/ViewSchedule.vue';
import ViewProfile from '../views/ViewProfile.vue';
import ViewLogs from '../views/ViewLogs.vue';
import HomePage from '../views/HomePage.vue';
import ViewInsight from '../views/ViewInsight.vue';
import ViewComplaints from '../views/ViewComplaints.vue';
import LoginPage from '../views/LoginPage.vue';

const routes = [
  {
    path: '/attendance',
    name: 'ViewAttendance',
    component: ViewAttendance,
    meta: { requiresAuth: true, title: 'Attendance - IMAB CORE' }
  },
  {
    path: '/roster',
    name: 'ViewRoster',
    component: ViewRoster,
    meta: { requiresAuth: true, title: 'Roster - IMAB CORE' }
  },
  {
    path: '/insight',
    name: 'ViewInsight',
    component: ViewInsight,
    meta: { requiresAuth: true, title: 'Insight - IMAB CORE' }
  },
  {
    path: '/complaints',
    name: 'ViewComplaints',
    component: ViewComplaints,
    meta: { requiresAuth: true, title: 'Complaints - IMAB CORE' }
  },
  {
    path: '/profiles',
    name: 'ViewProfile',
    component: ViewProfile,
    meta: { requiresAuth: true, title: 'Profiles - IMAB CORE' }
  },
  {
    path: '/employees',
    name: 'ViewEmployees',
    component: ViewEmployees,
    meta: { requiresAuth: true, title: 'Employees - IMAB CORE' }
  },
  {
    path: '/logs',
    name: 'ViewLogs',
    component: ViewLogs,
    meta: { requiresAuth: true, title: 'Logs - IMAB CORE' }
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
    meta: { layout: 'none', public: true, title: 'Login - IMAB CORE' }
  },
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    meta: { requiresAuth: true, title: 'IMAB CORE' }
  },
  {
    path: '/schedule',
    name: 'ViewSchedule',
    component: ViewSchedule,
    meta: { requiresAuth: true, title: 'Schedule - IMAB CORE' }
  },
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Global navigation guard
router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();

  // Ensure auth is initialized
  if (!authStore.isInitialized) {
    await authStore.initializeAuth();
  }

  // Check if the route requires authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!authStore.isAuthenticated) {
      console.log('User not authenticated, redirecting to login');
      next({ name: 'LoginPage' });
    } else {
      next();
    }
  } else {
    next();
  }
  
  document.title = to.meta.title || 'IMAB';
});

export default router;