import { ref } from 'vue';

export function useClearEmployeeData() {
  const isClearingData = ref(false);

  function clearEmployeeData() {
    isClearingData.value = true;
    try {
      // Remove specified keys from localStorage
      const keysToRemove = [
        'employeesList',
        'companiesList'
      ];

      keysToRemove.forEach(key => localStorage.removeItem(key));
    } catch (error) {
      console.error('Error during employee data clearing:', error);
    } finally {
      isClearingData.value = false;
    }
  }

  return {
    isClearingData,
    clearEmployeeData
  };
}
