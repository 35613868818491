import { ref } from 'vue';
import axiosClient from '@/axiosClient';
import { API_ENDPOINTS } from '@/apiConfig';

export function useFetchExpiringRosters() {
    const data = ref(null);
    const error = ref(null);
    const loading = ref(false);

    function addExpiringDurationTag(rosters) {
        const now = new Date();
        
        const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const todayEnd = new Date(todayStart);
        todayEnd.setHours(23, 59, 59, 999);

        const tomorrowStart = new Date(todayStart);
        tomorrowStart.setDate(tomorrowStart.getDate() + 1);
        const tomorrowEnd = new Date(tomorrowStart);
        tomorrowEnd.setHours(23, 59, 59, 999);

        const sevenDaysLater = new Date(todayStart);
        sevenDaysLater.setDate(sevenDaysLater.getDate() + 7);
        sevenDaysLater.setHours(23, 59, 59, 999);

        return rosters.map(roster => {
            const lastRosterDate = new Date(roster.last_roster_date);
            let expiring_duration;

            if (lastRosterDate >= todayStart && lastRosterDate <= todayEnd) {
                expiring_duration = "TODAY";
            } else if (lastRosterDate >= tomorrowStart && lastRosterDate <= tomorrowEnd) {
                expiring_duration = "TOMORROW";
            } else if (lastRosterDate > tomorrowEnd && lastRosterDate <= sevenDaysLater) {
                expiring_duration = "THISWEEK";
            } else if (lastRosterDate > sevenDaysLater) {
                expiring_duration = "IN30DAYS";
            } else {
                expiring_duration = "EXPIRED";
            }

            return { ...roster, expiring_duration };
        });
    }

    async function fetchExpiringRosters() {
        loading.value = true;
        try {
            const url = API_ENDPOINTS.fetchInsightExpiringRosters;
            
            const response = await axiosClient.get(url);
            if (response.data) {
                data.value = addExpiringDurationTag(response.data);
                sessionStorage.setItem('expiringRosters', JSON.stringify(data.value));
            }
        } catch (err) {
            console.error('Error fetching expiring rosters:', err);
            error.value = 'Failed to fetch expiring rosters';
            if (axiosClient.isAxiosError(err)) {
                if (err.response) {
                    console.error('Error response:', err.response.data);
                    console.error('Error status:', err.response.status);
                    error.value = `Server error: ${err.response.status}`;
                } else if (err.request) {
                    console.error('Error request:', err.request);
                    error.value = 'No response from server';
                } else {
                    console.error('Error message:', err.message);
                    error.value = err.message;
                }
            }
        } finally {
            loading.value = false;
        }
    }

    return { data, error, loading, fetchExpiringRosters };
}
