import { ref } from 'vue';
import axiosClient from '@/axiosClient';
import { API_ENDPOINTS } from '@/apiConfig';

export function useEmployeeSingleCreate() {
  const isCreating = ref(false);
  const createError = ref(null);

  const createEmployee = async (employeeData) => {
    isCreating.value = true;
    createError.value = null;

    try {
      const response = await axiosClient.post(API_ENDPOINTS.createEmployee, employeeData);
      return response.data;
    } catch (error) {
      console.error('Error creating employee:', error);
      if (error.response) {
        createError.value = error.response.data.message || 'An error occurred while creating the employee.';
      } else if (error.request) {
        createError.value = 'No response received from the server.';
      } else {
        createError.value = 'An error occurred while sending the request.';
      }
      throw error;
    } finally {
      isCreating.value = false;
    }
  };

  return {
    isCreating,
    createError,
    createEmployee
  };
}
